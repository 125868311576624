import React from "react";
import { Button } from 'react-materialize';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import { Modal } from 'react-materialize';

class ReportDraft extends React.Component {
  constructor() {
    super();

    this.state = {
      confirmDelete: false,
      expanded: false,
    };
  }

  goToReport(url) {
    window.location.href = "/reports/edit?"+url;
  }

  destroyReport() {
    this.props.destroyReport(this.state.confirmDelete.id, this.props.authToken)
    this.setState({confirmDelete: false});
    this.props.delete()
  }

  render() {
    return this.state.deleted ? null : (
      <div 
        class="report-draft"
        onMouseEnter={() => this.setState({expanded: true})}
        onMouseLeave={() => this.setState({expanded: false})}
      >
    
        <span class="draft-header">
          {this.props.draft.title}
        </span>
        <div class="draft-content">
          <div class={`draft-info ${this.state.expanded ? 'hidden' : ''}`}>
            <div class="info"><b>Author:</b>&nbsp;{this.props.draft.author} </div>
            <div class="info"><b>Created:</b>&nbsp;{this.props.draft.created} </div>
            <div class="info"><b>Last Edited:</b>&nbsp;{this.props.draft.updated} </div>
            <div class="info"><b>On Site:</b>&nbsp;{this.props.draft.onsite} </div>
            <div class="info"><b>Visible Components:</b>&nbsp;{this.props.draft.components?.length} </div>
            {/* <div class="info"><b>Recipients Selected:</b>&nbsp;{this.props.draft.recipients?.length} </div> */}
            <div class="button-container">
              <Button
                className={"draft-button"}
                waves="light"
                onClick={() => this.goToReport(this.props.draft.url)}
              >Edit Draft</Button>
        
              <Button
                className="draft-button destroy-report red"
                waves="light"
                onClick={() => this.setState({confirmDelete: this.props.draft})}
              >Delete Draft</Button>
            </div> 
          </div>
          {/* <div class="component-summary">
            {this.props.draft.components.slice(0,4).map(c => (
              <div class="component">
                {c["name"]} ... {c["completed"]}%
              </div>
            ))}
            <span style={{textAlign: 'center'}}>{this.props.draft.components.length > 4 ? `+ ${this.props.draft.components.length-4} omitted components` : ''}</span>
          </div> */}
        </div>
        
        
        {this.state.confirmDelete ? (
          <Modal
            id="confirm"
            open={this.state.confirmDelete}
            actions={[
              <Button flat onClick={() => this.setState({confirmDelete: false})}>Never mind</Button>,
              <Button className="red" waves="light" onClick={() => this.destroyReport()}>Yes I'm sure</Button>
            ]}
            options={{
              onCloseStart: () => this.setState({confirmDelete: false})
            }}
          >
            Are you sure you wanna delete this report?
          </Modal>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDraft);