import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF"];

class DragDrop extends React.Component {

  render() {
    // const [file, setFile] = useState(null);
    // const handleChange = (file) => {
    //   setFile(file);
    // };
    return (
      <FileUploader 
        // handleChange={handleChange} 
        name="file" 
        types={fileTypes} 
        hoverTitle={(<div/>)}
        onTypeError={() => alert('png, jpg, or gif only please')}
        children={(
          <div class="dropzone-container">
            <p>Drag a file into this box to upload,</p>
            <p>or click to select a file</p>
          </div>
        )}
        onDrop={file => this.props.upload(file)}
        onSelect={file => this.props.upload(file)}
      />
    );
  }
}

export default DragDrop;