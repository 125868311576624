import React from "react";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import M from "materialize-css";
import TextareaAutosize from 'react-textarea-autosize';
import { Textfit } from 'react-textfit';

import RemoveIcon from '@mui/icons-material/Remove';
// import EditIcon from '@mui/icons-material/Edit';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


class ModalEvent extends React.Component {
  constructor() {
    super();

    this.state = {
      editMode: false,
      showEventRepeatOptions: false,
      showEditRecurrenceOptions: false,
      editFutureRecurrence: false,
      showDeleteOptions: false
    };

    this.timepickerInput = React.createRef();
    this.datepickerTrigger = React.createRef();
  }

  updateTimer;

  startUpdateTimer() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
      this.updateTimer = null;
    }

    this.updateTimer = setTimeout(() => {
      this.props.updateEvent(
        this.props.authToken,
        this.props.event
      );
    }, 2000);
  }

  componentDidMount() {
    let timepicker = document.querySelectorAll('.timepicker, .time-icon');
    let timepickerOptions = {
      autoClose: true,
      container: '.schedule-container',
      defaultTime: 'now',
      onCloseEnd: () => {
        let time = timepicker[0].value;

        // console.log('closing timerpicker with value', time)

        this.props.updateEvent(this.props.authToken, {
          ...this.props.event,
          time_in: time
        })
      }
    };
    let tInstance = M.Timepicker.init(timepicker, timepickerOptions);
    let datepickers = document.querySelectorAll('.datepicker');
    let datepickerOptions = {
      autoClose: true,
      format: 'mmm d, yyyy',
      container: '.schedule-container',
      defaultDate: new Date(this.props.event.scheduled_on.replaceAll('-', '/')),
      setDefaultDate: true,
      // container: document.querySelectorAll('.report-body'),
      minDate: new Date(),
      onSelect: (d) => {
        this.props.updateEvent(this.props.authToken, {
          ...this.props.event,
          scheduled_on: d,
          originally_scheduled: this.props.event.scheduled_on,
          edit_future: this.state.editFutureRecurrence
        }, this.fetchCalendar())
      }
    };
    let dInstance = M.Datepicker.init(datepickers, datepickerOptions)
  }

  fetchCalendar() {
    // get month string as "YYYY-MM"
    let monthString = new Date(this.props.event.scheduled_on.replaceAll('-', '/')).toISOString().slice(0,7); //woah mama
    this.props.fetchCalendar(this.props.authToken, monthString, null, null);
  }

  deleteEvent(clickEvent, scheduleEvent, editFuture) {
    clickEvent.stopPropagation();

    scheduleEvent.edit_future = editFuture;

    this.props.destroyEvent(this.props.authToken, scheduleEvent);
  }

  ordinal_suffix(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }

  repeatEvent(recurrenceType) {
    this.props.updateEvent(this.props.authToken, {
      ...this.props.event,
      recurrence_type: recurrenceType,
    })
  }

  serviceText() {
    let date = new Date(this.props.event.scheduled_on.replaceAll('-', '/'));
    let frequency = this.props.event?.frequency;
    
    if (frequency.cooling === frequency.heating) {
      return `Due ${frequency.cooling}`
    } else {
      let activeSeason = [4,5,6,7,8,9].includes(date.getMonth()+1) ? 'cooling' : 'heating';
      return `Due ${frequency[activeSeason]} during ${activeSeason}`;
    }
  }

  eventFrequency() {
    let date = new Date(this.props.event.scheduled_on.replaceAll('-', '/'));
    let activeSeason = [4,5,6,7,8,9].includes(date.getMonth()) ? 'cooling' : 'heating';
    return this.props.event.frequency[activeSeason];
  }

  goToReport() {
    if (this.props.event.report_id) {
      window.location.href = `/reports/edit?${this.props.event.report_url}`
    } else {
      this.props.createReport(
        this.props.event.customer_id, 
        'sr', 
        this.props.event.id, 
        this.props.authToken, 
        (newReportUrl) => {window.location.href = `/reports/edit?${newReportUrl}`}
      );
    }
  }

  render() {
    let date = new Date(this.props.event.scheduled_on.replaceAll('-', '/'));
    let wday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let wdayString = `${this.ordinal_suffix(Math.round(date.getDate()/7))}`;
    wdayString = wdayString.concat(` ${wday[date.getDay()]}`);

    return (
      <div className={`event-container ${this.props.event.status}`}>
        <div className="event-info-container">
          {this.state.editMode ? (
            <span className="account-name">
              {this.props.event.title || this.props.accounts.filter(a => a.id === this.props.event.customer_id)[0]?.name}
            </span>
          ) : (
            <input
              onChange={(e) => this.props.updateEvent(this.props.authToken, {
                ...this.props.event,
                title: e.target.value
              })}
              className="account-name"
              value={this.props.event.title || this.props.accounts.filter(a => a.id === this.props.event.customer_id)[0]?.name}
            />
          )}

          <div className="scheduling-info">
            <div className="pill">
              <CalendarMonthIcon />
              {this.serviceText()}
            </div>
            <button className={`${this.props.event.status} pill`} onClick={() => this.goToReport()}>
              Report {this.props.event.status}
            </button>
          </div>

          <TextareaAutosize 
            className="materialize-textarea event-note"
            minRows={1}
            onChange={(e) => {
              this.props.updateEvent(null, {...this.props.event, note: e.target.value});
              this.startUpdateTimer();
            }}
            value={this.props.event.note || ''}
            placeholder="Enter a note"
          />

          {this.state.showEventRepeatOptions ? (
            <div className="event-repeat-options-container">
              <span>{this.props.event.title}</span>
              <span>{this.serviceText()}</span>
              <div className="buttons-container">
                {this.eventFrequency() === 'Monthly' ? [
                  <button onClick={() => this.repeatEvent(1)}>Repeat on the {this.ordinal_suffix(date.getDate())}</button>,
                  <button onClick={() => this.repeatEvent(2)}>Repeat on {wdayString}</button>
                ] : null}
                {this.eventFrequency() === 'Weekly' ? [
                  <button onClick={() => this.repeatEvent(2)}>Repeat on {wday[date.getDay()]}s</button>
                ] : null}
                <button onClick={() => this.setState({showEventRepeatOptions: false})}>Cancel</button>
              </div>
            </div>
          ) : null}

          {this.state.showEditRecurrenceOptions ? (
            <div className="event-reschedule-options-container">
              <button onClick={() => {
                this.setState({editFutureRecurrence: true});
                this.datepickerTrigger.current.click();
              }}>Edit all future occurrences</button>
              <button onClick={() => this.datepickerTrigger.current.click()}>Edit just this occurrence</button>
              <button onClick={() => this.setState({showEditRecurrenceOptions: false})}>Cancel</button>
            </div>
          ) : null}

          {this.state.showDeleteOptions ? (
            <div className="event-delete-confirmation-container">
              <div className="buttons-container confirm-delete-container">
                {this.props.event.recurrence_type ? ([
                  <button onClick={(e) => this.deleteEvent(e, this.props.event, 0)}>Delete just this event</button>,
                  <button onClick={(e) => this.deleteEvent(e, this.props.event, 1)}>Delete all future occurrences</button>,
                  <button onClick={() => this.setState({showDeleteOptions: false})}>Cancel</button>
                ]) : ([
                  <button className="confirm-delete delete-final" onClick={(e) => this.deleteEvent(e, this.props.event, null)}>Remove from schedule?</button>,
                  <button className="confirm-delete" onClick={() => this.setState({showDeleteOptions: false})}>Cancel</button>
                ])}
              </div>
            </div>
          ) : null}

          <button className="datepicker hidden" ref={this.datepickerTrigger}></button>
        </div>
        <div class="buttons-container">
          {this.props.event.recurrence_type ? null : (
            <button 
              class="button repeat-event" 
              onClick={() => this.setState({showEventRepeatOptions: true})}
            >
              <EventRepeatIcon onPress={() => this.setState({showEventRepeatOptions: true})}/>
            </button>
          )}

          {this.props.event.recurrence_type ? (
            <button 
              class="button reschedule" 
              onClick={(e) => this.setState({showEditRecurrenceOptions: true})}
            >
              <EditCalendarIcon />
            </button>
          ) : (
            <button className="button reschedule" onClick={() => this.datepickerTrigger.current.click()}>
              <EditCalendarIcon />
            </button>
          )}

          <button 
            className="button delete-event" 
            // onClick={(e) => this.deleteEvent(e, event)}
            onClick={(e) => this.setState({showDeleteOptions: true})}
          >
            <RemoveIcon />
          </button>

          <input id="onsite-date" class="hidden-input picker-input datepicker" value={this.props.event.scheduled_on}></input>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    accountsList: state.accountsList,
    accounts: state.accounts,
    authToken: state.authToken,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEvent);
