import React from 'react';
import {Helmet} from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './Login';
import Dashboard from './Dash';
import ReportDash from './report/ReportDash';
import ReportEdit from './report/ReportEdit';
import LookupMain from './lookup/Main';
import ScheduleMain from './schedule/Main';
import Error from './Error';
import Loading from './Loading';

import HandleToken from './AuthService';

import Nav from './components/Nav.js';

import 'materialize-css';
import "@fontsource/roboto"; // Defaults to weight 400.
import { Button } from 'react-materialize';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from './redux/actions'; //Import your actions

import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: 'f76ce1ea-1763-4dd0-b347-0025b2162386',
    redirectUri: 'http://localhost:3000',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const login = async () => {
  const loginRequest = {
    scopes: ['user.read', 'openid', 'profile'], // Define the required permissions
  };

  try {
    await msalInstance.loginPopup(loginRequest);
    // Handle successful login
  } catch (error) {
    // Handle login error
  }
};

export const logout = () => {
  msalInstance.logout();
};

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      location: window.location.href
    };
  }

  componentDidMount() {
    let token = this.props.authToken;

    if (token) {this.props.refreshSession(token)};

    this.setState({location: window.location.pathname});
  }



  // route() {
  //   let loc = window.location.pathname;

  //   if (!this.props.authToken) {
  //     return <Login />
  //   }

  //   switch (loc) {
  //     case '/':
  //       return <ReportDash />
  //     case '/reports':
  //       return <ReportDash />
  //     case loc.match(/\/reports\/edit/)?.input:
  //       return <ReportEdit />
  //     case '/lookup':
  //       return <LookupMain />
  //     case '/schedule':
  //       return <ScheduleMain />
  //     default:
  //       // return <div>404</div>
  //       return <Dashboard />
  //   }
  // }

  render() {
    if (this.props.authToken) {
      return (
        <div>
          <Loading />
          <Helmet>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0-alpha.3/css/materialize.min.css" />
          </Helmet>
          {/* {this.route()} */}
          <Nav location={window.location.pathname.replace('/', '')}>
            <Router>
              <Routes>
                <Route path="/" element={<ReportDash/>} />
                <Route path="/reports" element={<ReportDash/>} />
                <Route path="/reports/edit" element={<ReportEdit/>} />
                <Route path="/lookup" element={<LookupMain/>} />
                <Route path="/schedule" element={<ScheduleMain/>} />
                <Route path="/auth_token_redirect" component={<HandleToken/>} />
              </Routes>
            </Router>
          </Nav>
          <Error error={this.props.error} acknowledge={() => this.props.clearError()} />
        </div>
      )
    } else {
      return (
        <div>
          <Loading />
          <Helmet>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0-alpha.3/css/materialize.min.css" />
          </Helmet>
          <Login />
          <Error error={this.props.error} acknowledge={() => this.props.clearError()} />
        </div>
      )
    }

  }
}

function mapStateToProps(state, props) {
  return {
    // report: state.report,
    user: state.user,
    authToken: state.authToken,
    error: state.error,
    loading: state.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
