import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import DragDrop from './DragDrop';
import 'materialize-css';
import '../../styles/report.scss';
import TextareaAutosize from 'react-textarea-autosize';
import CheckIcon from '@mui/icons-material/Check';


class ImageUpload extends React.Component {

  constructor() {
    super();
    this.state = {
      file: null, // base64 data
      note: ''
    };
  }

  encodeFile(file) {
    var fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      this.setState({file: fileReader.result});
    });

    fileReader.readAsDataURL(file)
  }

  addImage(e) {
    e.stopPropagation();

    let id = Date.now();

    let noteObj = {
      id: id,
      report_id: this.props.report.id,
      note_type: 'image',
      content: this.state.note,
      component_id: this.props.component.id,
      image: this.state.file,
      iAm: 'image',
      breadcrumbs: (
        this.props.component.id === '-1' ? 
        `${this.props.report.id}.components.log.nodes.${new Date().toLocaleString().split(',')[0]}.${id}` :
        `${this.props.report.id}.components.${this.props.component.id}.notes.${id}`
      )
    };

    console.log('FIRING IMAGEUPLOAD UPDATE', this.buildScaffold(noteObj))

    this.props.updateReport(
      this.buildScaffold(noteObj),
      {fridge: true, freezer: this.props.authToken},
      () => this.props.close()
    );
  }

  buildScaffold(node) {
    let breadcrumbs = node.breadcrumbs.split('.');
    let reportId = breadcrumbs.reverse().pop();
    let reportScaffold = {
      id: reportId,
      ...breadcrumbs.reduce((val, key) => ({[key]: val}), {...node})
    };
   
    return reportScaffold;
  }

  handleInputKeypress(event) {
    if (event.key === "Enter") {
      event.preventDefault();

      this.addImage(event);
    }
  }

  render() {
    return (
      <div class="report-modal" onClick={() => this.props.close()}>
        {this.state.file ? ([
          <img class="file-upload-preview" src={this.state.file} alt="uploaded" onClick={e => e.stopPropagation()}/>,
          <div class="edit-node-container">
            <TextareaAutosize
              className="note-input"
              placeholder="Enter a note or comment"
              autoFocus
              onKeyPress={e => this.handleInputKeypress(e)} // submit note on enter input
              onClick={e => e.stopPropagation()}
              onChange={e => this.setState({note: e.target.value})}
              onFocus={function(e) { // sets the cursor after the input value
                var val = e.target.value;
                e.target.value = '';
                e.target.value = val;
              }}
              value={this.state.note}
            />

            <button class="submit-note" onClick={e => this.addImage(e)}>
              <CheckIcon />
            </button>
          </div>
        ]) : (
          <div onClick={(e) => e.stopPropagation()}>
            <DragDrop upload={file => this.encodeFile(file)}/>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
