import React from 'react';

import { Modal } from 'react-materialize';
import 'materialize-css';

class Error extends React.Component {
  render() {
    if (this.props.error) {
      return (
        <Modal
          open={true}
          options={{
            onCloseStart: () => this.props.acknowledge()
          }}
        >
          {this.props.error}
        </Modal>
      )
    } else {
      return null
    }
  }
}

export default Error;
