import React, { useEffect, useRef } from 'react';

const HistoryCanvas = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let history = props.test.history.map(h => h.result);
    let bounds = props.test.bounds;
    let limits = [props.test?.min, props.test?.max];

    console.log('about to draw canvas with', canvas, history, bounds, limits);

    //transform canvas so [0,0] is bottom left
    ctx.transform(1, 0, 0, -1, 0, canvas.height);

    //draw first point history graph
    ctx.beginPath();
    ctx.moveTo(canvas.width/history.length/2, 0); //start at point value, draw to graph point
    ctx.setLineDash([5, 10]);
    ctx.strokeStyle = 'grey';
    ctx.lineTo(1, (history[0] ? history[0]-bounds[0] : 0)/(bounds[1]-bounds[0])*100+10);
    ctx.stroke();

    var [x, y] = [1, (history[0] ? history[0]-bounds[0] : 0)/(bounds[1]-bounds[0])*100+10];

    // console.log('drawing', x, y);
    for (var i = 1; i < history.length; i++) {
      ctx.beginPath();
      ctx.moveTo(x, y)
      x = (i / (history.length-1)) * (canvas.width-1);
      y = (history[i] ? history[i]-bounds[0] : 0)/(bounds[1]-bounds[0])*100+10
      // console.log('drawing', x, y);

      ctx.setLineDash([]);
      ctx.strokeStyle = 'blue';
      ctx.lineTo(x, y);
      ctx.stroke();

      ctx.beginPath();
      ctx.setLineDash([5, 10]);
      ctx.strokeStyle = 'grey';
      ctx.moveTo(canvas.width/history.length*(i+1)-canvas.width/history.length/2, 0);
      ctx.lineTo(x, y);
      ctx.stroke();
    }

    //drawing limit lines
    ctx.setLineDash([10, 5])
    if (limits[0] !== null) {
      y = limits[0] === bounds[0] ? 10 : (limits[0]-bounds[0])/(bounds[1]-bounds[0])*100+10;
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.strokeStyle = 'orange';
      ctx.lineTo(canvas.width, y);
      ctx.stroke();
    }
    if (limits[1] !== null) {
      y = limits[1] === bounds[1] ? canvas.height-1 : (limits[1]-bounds[0])/(bounds[1]-bounds[0])*100+10;
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.strokeStyle = 'orange';
      ctx.lineTo(canvas.width, y);
      ctx.stroke();
    }
  }, []);

  return (
    <div className={`history-container shown`}>
      <canvas
        ref={canvasRef}
        id={`history-${props.test.testId}`}
        width="300" height="110"
        class="result-history"
      >
        Your browser does not support HTML Canvas.
      </canvas>

      <div class="x-axis">
        {props.test.history?.map(h => (
          <div class="y-value">
            {h.result || 'N/A'}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HistoryCanvas