import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import 'materialize-css';
import TextareaAutosize from 'react-textarea-autosize';
import '../../styles/report.scss';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Modal } from 'react-materialize';


class EditReportNode extends React.Component {

  constructor() {
    super();

    this.state = {
      confirmDelete: false
    };
  }

  confirmDelete() {
    let confirmText = '';

    if (this.props.node.content?.length > 0 && this.props.node.image?.length > 0) {
      confirmText = "note and image";
    } else if (this.props.node.content?.length > 0) {
      confirmText = "note";
    } else if (this.props.node.image?.length > 0) {
      confirmText = "image";
    }

    this.setState({confirmDelete: `Delete ${confirmText}?`});
  }

  buildScaffold(node) {
    let breadcrumbs = node.breadcrumbs.split('.');
    let reportId = breadcrumbs.reverse().pop();
    let reportScaffold = {
      id: reportId,
      ...breadcrumbs.reduce((val, key) => ({[key]: val}), {...node})
    };
   
    return reportScaffold;
  }

  handleSubmitButtonPress(event) {
    event.stopPropagation();

    this.props.updateReport(
      this.buildScaffold({...this.props.node, synced: false}), 
      {fridge: true, freezer: this.props.authToken}, 
      null
    );

    this.props.close();
  }

  handleInputChange(event) {
    let noteObj = {
      ...this.props.node,
      content: event.target.value,
      synced: false
    };

    this.props.updateReport(
      this.buildScaffold(noteObj), 
      {fridge: true}, 
      this.props.switchFocus(noteObj)
    )
  }

  handleInputKeypress(event) {
    if (event.key === "Enter") {
      event.preventDefault();

      this.props.updateReport(
        this.buildScaffold({...this.props.node, synced: false}), 
        {fridge: true, freezer: this.props.authToken}, 
        () => this.props.close()
      );
    }
  }

  deleteNote(event) {
    event.stopPropagation();

    let noteObj = {
      ...this.props.node,
      deleted: true,
      synced: false
    };

    this.props.updateReport(
      this.buildScaffold(noteObj),
      {fridge: true, freezer: this.props.authToken},
      null
    );

    this.props.close();
  }

  render() {
    return (
      <div
        id="edit-node"
        class={`report-modal`}
        onClick={(e) => this.handleSubmitButtonPress(e)}
      >
                
        {/* DEBUG */}
        {/* <div style={{width: 300, overflowWrap: 'break-word', color: 'white'}}>{JSON.stringify(this.props.node)}</div> */}

        {
          this.props.node.image ? 
          <img src={this.props.node.image} class="file-upload-preview" alt="file upload"/> : null
        }
        
        <div class="edit-node-container">
          <TextareaAutosize
            className="note-input"
            placeholder="Enter a note or comment"
            autoFocus
            onClick={e => e.stopPropagation()}
            onKeyPress={e => this.handleInputKeypress(e)} // submit note on enter input
            onChange={e => this.handleInputChange(e)}
            onFocus={function(e) { // sets the cursor after the input value
              var val = e.target.value;
              e.target.value = '';
              e.target.value = val;
            }}
            value={this.props.node.content} 
          />

          <button class="submit-note" onClick={(e) => this.handleSubmitButtonPress(e)}>
            <CheckIcon color={this.props.node.synced ? '#0086fe' : '#4a0098'}/>
          </button>

          <button class="delete-note" onClick={(e) => {
            e.stopPropagation();
            this.confirmDelete();
          }}>
            <DeleteIcon />
          </button>
        </div>

        <Modal
          id="confirm"
          open={this.state.confirmDelete?.length > 0}
          actions={[
            <Button flat onClick={() => this.setState({confirmDelete: false})}>Never mind</Button>,
            <Button className="red" waves="light" onClick={(e) => this.deleteNote(e)}>Yup get it outta here</Button>
          ]}
          options={{
            onCloseStart: () => this.setState({confirmDelete: false})
          }}
        >
          {this.state.confirmDelete}
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditReportNode);
