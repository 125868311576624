import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import 'materialize-css';
import Confetti from 'react-confetti';
import '../../styles/report.scss';

const width = window.width;
const height = window.height;

class SubmitSuccess extends React.Component {
  render() {      
    return (
      <div class={`report-modal`} onClick={() => this.props.close()}>
        <Confetti
          width={width}
          height={height}
          numberOfPieces={20}
          initialVelocityY={0}     
          recycle={false}
        />
        <div class="submit-success-container">

          <div class="header">
            Report submitted successfully!
          </div>

          <div class="options-container">
            <a class="view-link" href={this.props.report.url}>
              View Report
            </a>

            <a class="back-link" href="/reports">
              Back to Dash
            </a>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    report: state.report
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitSuccess);
