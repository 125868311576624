import React from 'react';
import './styles/loading.scss';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from './redux/actions'; //Import your actions


class Loading extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="background-fade">
          <img className="loading-spinner" src="./proasys_alt.png" alt="loading-icon" height="100px"/>
        </div>
      )
    } else {
      return false
    }
  }
}

function mapStateToProps(state, props) {
  return {
    loading: state.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
