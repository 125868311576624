import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import { Button, Modal } from 'react-materialize';

import ReportDraft from './report_components/ReportDraft';

class ReportDrafts extends React.Component {

  componentDidMount() {
    this.props.fetchReportDrafts(null, this.props.authToken);
  }

  render() {
    return (
      <div class="content-container">

        <div class="report-draft-container">
          {this.props.drafts?.map(d => <ReportDraft draft={d} />)}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    drafts: state.drafts,
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDrafts);
