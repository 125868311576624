import React from 'react';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';

import 'materialize-css';
import M from 'materialize-css';
import '../styles/report.scss';

import ReportTest from './report_components/ReportTest';
import ReportNote from './report_components/ReportNote';
import NodeWorkbench from './report_components/NodeWorkbench';
import RecipientSelect from './report_components/RecipientSelect';
import PreSubmit from './report_components/PreSubmit';
import SubmitSuccess from './report_components/SubmitSuccess';
import { Checkbox } from 'react-materialize';


import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


class ReportEdit extends React.Component {
  constructor() {
    super();

    this.state = {
      editMetadata: false,
      hideSidebar: false,
      componentFocused: null,
      nodeFocused: null,
      pickersInitialized: false,
      recipientSelectShown: false,
      preSubmitShown: false,
      submitted: false,
      editTitle: false,
      headerShown: true
    }

    this.scrollPosition = 0; // only updated when user scrolled >40px in a single direction
  }

  componentDidMount() {
    this.props.showNav(false);

    this.props.fetchReport(
      this.props.authToken, // auth
      window.location.search.split('?')[1] // report hash_id
    );

    this.props.fetchTestFields(this.props.authToken);
  }

  componentDidUpdate() {
    if (!this.props.report) {return false}
    // init datepicker
    let datepickers = document.querySelectorAll('.datepicker');
    let datepickerOptions = {
      autoClose: true,
      format: 'mmm d, yyyy',
      defaultDate: new Date(this.props.report.date_of_visit),
      setDefaultDate: true,
      container: document.querySelectorAll('.report-body'),
      maxDate: new Date(),
      onSelect: (d) => this.props.updateReport({
        id: this.props.report.id,
        date_of_visit: d.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      }, {fridge: true, freezer: this.props.authToken}, null)
    };
    let dInstance = M.Datepicker.init(datepickers, datepickerOptions)

    let timepickers = document.querySelectorAll('.timepicker');
    let timepickerOptions = {
      autoClose: true,
      container: '.report-body',
      defaultTime: 'now',
      onCloseEnd: () => {
        let times = Array.prototype.slice.call(timepickers).map(picker => {
          return picker.value
        });

        this.props.updateReport({
          id: this.props.report.id,
          onsite_start: times[0].replace(/^0+/, ''),
          onsite_end: times[1].replace(/^0+/, '')
        }, {fridge: true, freezer: this.props.authToken}, null);
      }
    }
    let tInstance = M.Timepicker.init(timepickers, timepickerOptions);

  
    // scroll to component in url
    // let tag = window.location.href.split('#')[1];
    // if (tag) {
    //   document.querySelectorAll('.report-body')[0].scrollTo({
    //     top: document.querySelectorAll(`#comp-${tag}`)[0].clientHeight+90, behavior: 'smooth'
    //   })
    // }
  }

  validateResult(result) { // make sure value entered in result is a valid number
    return result?.replace(/[^0-9.]/g,'');
  }

  handleTitleInteraction(e) {
    if (e) {
      e.preventDefault()
      // for some reason if we dont return here,
      // pressing enter on title input will trigger this fn twice
      return
    };

    if (!this.state.editTitle) {
      // show and focus title input
      this.setState({editTitle: true});

      setTimeout(() => {
        document.getElementById("report-title-input").focus();
      }, 100)
    } else {
      // hide and unfocus title input, save report
      document.getElementById("report-title-input").blur();
      this.setState({editTitle: false})
    }
  }

  handleScroll(e) {
    // console.log('scrolling', e);
    let scrollingUp = e.deltaY < 0;
    
    // Only update headerShown if the user has scrolled at least 40 pixels
    if (Math.abs(e.currentTarget.scrollTop - this.scrollPosition) >= 40) {
      console.log('scrolled 40, headerShown:', scrollingUp)
      this.setState({ headerShown: scrollingUp });
      this.scrollPosition = e.currentTarget.scrollTop;
    }
  }

  toggleSidebar() {
    this.setState({hideSidebar: !this.state.hideSidebar});
  }

  formatLimitsText(min, max) {
    if (min && max) {
      return <span className="limits">{` (${min} — ${max})`}</span>
    } else if (min) {
      return <span className="limits">{` (min ${min})`}</span>
    } else if (max) {
      return <span className="limits">{` (max ${max})`}</span>
    } else {
      return null
    }
  }

  scrollToComponent(id) {
    console.log('scrolling to', `comp-${id}`);
    document.getElementById(`comp-${id}`).scrollIntoView({behavior: 'smooth'});
  }

  groupComponents() {
    let compsArray = Object.entries(this.props.report.components).map(c => c[1]);
    let compGroups = [];

    for (var i=0; i<compsArray.length; i++) {
      if (compsArray[i].group) {
        if (!compGroups.includes(compsArray[i].group)) {
          compGroups.push(compsArray[i].group)
        }
      }
    }

    return compGroups
  }

  componentIsVisible(comp) {
    let hidden = this.props.report.hidden_components || [];
    return !hidden.includes(`${comp.id}`);
  }

  componentGroupIsVisible(groupName) {
    let group = Object.entries(this.props.report.components).map(c => c[1]).filter(c => c.group === groupName);
    return group.filter(c => !this.componentIsVisible(c)).length < group.length;
  }

  toggleComponentVisibility(event, comp, groupName) {
    event.stopPropagation(); // dont click through to trigger parent

    let hiddenComps = this.props.report.hidden_components;

    if (groupName) {
      // we gotta hide/show a whole group of components
      let groupIds = Object.entries(this.props.report.components).map(c => c[1]).filter(c => c.group === groupName).map(c => `${c.id}`);
      for (var i=0; i<groupIds.length; i++) {
        // remove components from hidden array
        hiddenComps = hiddenComps.filter(c => !groupIds.includes(c));
        
        if (this.componentGroupIsVisible(groupName)) { // we're gonna hide em
          hiddenComps = hiddenComps.concat(groupIds); // tack em onto the array
        }
      }
    } else if (comp) {
      // just hiding one component
      let visible = this.props.report.hidden_components.includes(`${comp.id}`);
      hiddenComps = visible ? hiddenComps.filter(id => id !== `${comp.id}`) : hiddenComps.concat(`${comp.id}`);
    }

    this.props.updateReport(
      {id: this.props.report.id, hidden_components: hiddenComps},
      {fridge: true, freezer: this.props.authToken},
      null
    );
  }

  generateAuthorText() {

    if (this.props.report.submitted) {
      let submitDate = new Date('2022-11-30T21:23:46.000Z').toLocaleDateString();
      let submitTime = new Date(this.props.report.submitted).toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit'})

      if (submitDate === new Date().toLocaleDateString()) {
        return `Submitted at ${submitTime} by ${this.props.report.author}`
      } else {
        return `Submitted on ${submitDate} by ${this.props.report.author}`
      }
    } else {
      return `Report by ${this.props.report.author}`
    }
  }

  renderModalComponents() {
    if (this.state.recipientSelectShown) {
      return (
        <RecipientSelect 
          close={() => this.setState({recipientSelectShown: false})}
        />
      )
    } else if (this.state.preSubmitShown) {
      return (
        <PreSubmit
          close={() => this.setState({preSubmitShown: false})}
          triggerRecipientSelect={() => this.setState({recipientSelectShown: true})}
          onSuccess={() => this.setState({submitted: true})}
        />
      )
    } else if (this.state.componentFocused || this.state.nodeFocused) {
      return (
        <NodeWorkbench
          // yup we're gonna hoist these props cry about it
          component={this.state.componentFocused} 
          node={this.state.nodeFocused} 
          switchFocus={(node, comp) => this.setState({nodeFocused: node, componentFocused: comp})}
          close={() => this.setState({componentFocused: null, nodeFocused: null})}
        />
      )
    } else if (this.state.submitted) {
      return <SubmitSuccess/>
    };
  }

  render() {

    if (!this.props.report) {
      return ('loading')
    }

    return (
      <body>
        <div className="app-container">
          <div className={`report-header ${this.props.report.submitted ? 'submitted' : ''} ${this.state.headerShown ? '' : 'hidden'}`}>
            <div className="logo-container" onClick={() => window.location.href="/reports"}></div>
            <div className="header-content">
              <div className='report-title-container' onClick={() => this.handleTitleInteraction()}>
                {this.state.editTitle ? (
                  <form onSubmit={e => this.handleTitleInteraction(e)}>
                    <input
                      type="text"
                      id="report-title-input"
                      className="title-input"
                      value={this.props.report?.title}
                      placeholder="Edit Report Title"
                      onChange={(event) => {
                        this.props.updateReport({title: event.target.value}, {fridge:true})
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onBlur={() => this.props.updateReport(
                        {id: this.props.report.id, title: this.props.report.title}, 
                        {fridge: true, freezer: this.props.authToken},
                        () => this.setState({editTitle: false})
                      )}
                    />

                    <CheckIcon className="edit-title submit-title" />
                    <button type="submit" style={{display:'none'}}>submit</button>
                  </form>
                ) : (
                  <span className="report-title-container" style={{display: this.state.editTitle ? "none" : "flex"}}>
                    <span className="report-title">
                      {this.props.report?.title}
                    </span>

                    <CreateIcon className="edit-title" />
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="content-container">
            <div className={`components-sidebar ${this.state.hideSidebar ? 'hidden' : ''}`}>

              <div className="sidebar-toggle" onClick={() => this.toggleSidebar()}>
                <MenuIcon />
              </div>

              <div className="components-container">
                {this.groupComponents()?.map(group => (
                  <div className="component-group" key={group}>
                    <div className="group-name">
                      <span>{group}</span>
                      <button 
                        className={`visibility-toggle ${this.componentGroupIsVisible(group) ? 'visible' : 'invisible'}`} 
                        onClick={(e) => {this.toggleComponentVisibility(e, null, group)}} 
                      />
                    </div>
                    {Object.entries(this.props.report.components).filter(c => c[1].group === group).map(c => (
                      // wow that was gross
                      <div key={`comp-${c[1].id}`} data-comp={`comp-${c[1].id}`} className="component travel" onClick={() => this.scrollToComponent(c[1].id)}> 
                        <p className="component-name">
                          {c[1].name}
                        </p>
                        {
                          this.props.report.hidden_components?.includes(`${c[1].id}`) ?
                          <VisibilityOffIcon className="hidden" onClick={(e) => {this.toggleComponentVisibility(e, c[1])}} /> :
                          <VisibilityIcon onClick={(e) => {this.toggleComponentVisibility(e, c[1])}} />
                        }
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <a className="preview-report-button component" href={this.props.report.url} target="_blank" rel="noreferrer">
                Preview Report
              </a>
              <button className="recipient-select-button component" onClick={() => this.setState({recipientSelectShown: true})}>
                {this.props.report.recipients?.length ? `${this.props.report.recipients?.length} Recipients` : "Select Recipients"}
              </button>
              <button 
                className="submit-button component" 
                disabled={this.props.report.recipients?.length ? "" : "disabled"}
                onClick={() => this.setState({preSubmitShown: true})}
              >
                Submit Report
              </button>
            </div>

            <div className="report-body" onWheel={e => this.handleScroll(e)}>
              {Object.entries(this.props.report.components).map(c => c[0] !== '-1' ? ( // iterating over all components except log
                <div key={`comp-${c[1].id}`} id={`comp-${c[1].id}`} className={`report-results-container ${this.componentIsVisible(c[1]) ? '' : 'hidden'}`}>
                  {/* hiding components instead of derendering them. possibly a performance issue. */}
                  <div className="active-component-header">
                    {c[1].name}
                  </div>

                  {Object.entries(c[1]?.notes || {}).map((n, i) => (
                    <ReportNote 
                      key={n[1].id}
                      node={n[1]} 
                      handleClick={() => this.setState({nodeFocused: n[1], componentFocused: c[1]})} 
                    />
                  ))}

                  {Object.entries(c[1].tests).map((t, i) => (
                    <ReportTest 
                      key={t[1].id} 
                      test={t[1]} 
                      component={c[1]} 
                      tabIndex={i}
                    />
                  ))}

                  <div className="node-container" data-type="pseudonode">
                    <div
                      className="add-node"
                      data-comp={c.id}
                      data-target="new-node"
                      onClick={() => {this.setState({componentFocused: c[1]})}}
                    >
                      <AddIcon className="add-icon"/>
                      Add test, note or image
                    </div>
                  </div>
                </div>
              ) : null)}

              <div key={`comp--1`} id={`comp--1`} className="report-results-container service-log">
                <div className="active-component-header service-log">
                  Service Log
                  <VisibilityOffIcon className="invisible" />
                </div>
                <div className="node-container" data-type="pseudonode">
                  <div
                    className="add-node"
                    data-comp={'-1'}
                    data-target="new-node"
                    onClick={() => {this.setState({componentFocused: this.props.report.components['-1']})}}
                  >
                    <AddIcon className="add-icon"/>
                    Add test, note or image
                  </div>
                </div>
                {Object.entries(this.props.report.components['-1'].nodes).reverse().map(date => ([
                  <span className="log-date">{date[0]}</span>,
                  Object.entries(date[1]).map(node => ([
                    node[1].iAm === 'test' ? 
                    <ReportTest 
                      key={node[1].id} 
                      test={node[1]} 
                      component={this.props.report.components['-1']} 
                      log={true}
                    /> :
                    <ReportNote 
                      key={node[1].id} 
                      node={node[1]} 
                      handleClick={() => this.setState({nodeFocused: node[1], componentFocused: this.props.report.components['-1']})} 
                      log={true}
                    /> 
                  ]))
                ]))}
              </div>

              <div className='report-footer'>
                <div className="report-metadata">
                  <div className='report-title-container' onClick={() => this.handleTitleInteraction()}>
                    {this.state.editTitle ? (
                      <form onSubmit={e => this.handleTitleInteraction(e)}>
                        <input
                          type="text"
                          id="report-title-input"
                          className="title-input"
                          value={this.props.report?.title}
                          placeholder="Edit Report Title"
                          onChange={(event) => {
                            this.props.updateReport({title: event.target.value}, {fridge:true})
                          }}
                          onClick={(e) => e.stopPropagation()}
                          onBlur={() => this.props.updateReport(
                            {id: this.props.report.id, title: this.props.report.title}, 
                            {fridge: true, freezer: this.props.authToken},
                            () => this.setState({editTitle: false})
                          )}
                        />

                        <CheckIcon className="edit-title submit-title" />
                        <button type="submit" style={{display:'none'}}>submit</button>
                      </form>
                    ) : (
                      <span className="report-title-container" style={{display: this.state.editTitle ? "none" : "flex"}}>
                        <span className="report-title">
                          {this.props.report?.title}
                        </span>

                        <CreateIcon className="edit-title" />
                      </span>
                    )}
                  </div>

                  <span className="report-author">
                    {this.generateAuthorText()}
                  </span>

                  <form className="onsite-form">
                    <div>
                      <span>On site: </span>&nbsp;
                      <input id="onsite-date" className="picker-input datepicker" value={this.props.report.date_of_visit}></input>
                    </div>

                    <div>
                      <input 
                        id="onsite-start" 
                        className="picker-input timepicker" 
                        value={this.props.report.onsite_start}
                      /> 
                      &nbsp;—&nbsp;
                      <input 
                        id="onsite-end"
                        className="picker-input timepicker" 
                        value={this.props.report.onsite_end} 
                        placeholder=" "
                      /> 
                    </div>
                  </form>
                </div>
                <div className='halfsize-buttons'>
                  <a className='footer-button half preview' href={this.props.report.url} target="_blank" rel="noreferrer">
                    Preview Report
                  </a>
                  <button 
                    className='footer-button half recipients'
                    onClick={() => this.setState({recipientSelectShown: true})}
                  >
                    Select Recipients</button>
                </div>
                <button 
                  className='footer-button submit' 
                  onClick={() => this.setState({preSubmitShown: true})}
                  // disabled={this.props.report.recipients?.length ? "" : "disabled"}
                > 
                  Submit Report 
                </button>
              </div>

              {/* spacer if window height doesnt take up full device height */}
              {/* <div style="display:flex; flex-grow: 1"></div> */}
              
            </div>
          </div>

          {this.renderModalComponents()}
        </div>

        {/* <span>{JSON.stringify(this.state.componentFocused)}</span> */}
      </body>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    user: state.user,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportEdit);
