import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';


const msalConfig = {
  auth: {
    clientId: 'f76ce1ea-1763-4dd0-b347-0025b2162386',
    authority: 'https://login.microsoftonline.com/f5a21372-fe10-45ca-9073-853443da39b6',
    redirectUri: (process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://wave.proasysinc.com/') + 'auth_token_redirect'
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const login = async () => {

  console.log("fired login", msalInstance)
  const loginRequest = {
    scopes: ['user.read', 'openid', 'profile'], // Define the required permissions
  };

  try {
    await msalInstance.initialize();
    const authResponse = await msalInstance.loginPopup(loginRequest);
    console.log('cooked up', authResponse);
    
    return authResponse;
  } catch (error) {
    // Handle login error
  }
};

export const logout = () => {
  msalInstance.logout();
};

class HandleToken extends React.Component {
  render() {
    console.log('token acquired', this);
    return;
  }
}

export default HandleToken;