import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import '../styles/report.scss';
import { Autocomplete, Button, Checkbox } from 'react-materialize';
import 'materialize-css';
import ReportDraft from './report_components/ReportDraft';
import { Modal } from 'react-materialize';

class ReportNew extends React.Component {
  constructor() {
    super();
    this.state = {
      accountSelected: null,
      reportType: 'SR',
      foundDrafts: null
    }
  }

  componentDidMount() {
    this.props.fetchAccounts(this.props.authToken);

    document.getElementById("account-select").focus()
  }

  formatAccounts() {
    let accounts = this.props.accounts;
    let newObj = {};

    for (var i = 0; i < accounts?.length; i++) {
      newObj[`${accounts[i].name}`] = accounts[i].id;
    }

    return newObj;
  }

  switchReportType() {
    this.setState({reportType: this.state.reportType === 'SR' ? 'VR' : 'SR'});
  }

  newReport() {
    this.props.createReport(
      // customer, reportType, eventId, token, onSuccess
      this.state.accountSelected,
      this.state.reportType,
      null,
      this.props.authToken,
      (newReportUrl) => {window.location.href = `/reports/edit?${newReportUrl}`}
    )
  }

  accountSelected(a) {
    let account = this.formatAccounts()[a]
    console.log('seleced account', account, a);
    this.setState({accountSelected: account});
    this.props.fetchReportDrafts(account, this.props.authToken, (drafts) => {
      this.setState({foundDrafts: drafts});
      console.log('got these', drafts);
    });
  }

  render() {
    return (
      <div class="content-container">

        <Autocomplete
          id="account-select"
          className="new-report-account-input"
          options={{
            data: this.formatAccounts(),
            onAutocomplete: (a) => this.accountSelected(a)
          }}
          placeholder="Enter an account"
          onChange={() => this.setState({accountSelected: null, foundDrafts: null})}
        />

        <div class="under-row">
          <Button
            className="create-report-button"
            disabled={!this.state.accountSelected}
            onClick={() => this.newReport()}
          >
            Create Report
          </Button>

          {/* <div class="check-container">
            <Checkbox
              id="service-check"
              label="Service"
              value="SR"
              checked={this.state.reportType === 'SR'}
              onChange={() => this.setState({reportType: 'SR'})}
            />

            <Checkbox
              id="visit-check"
              label="Visit"
              value="VR"
              checked={this.state.reportType === 'VR'}
              onChange={() => this.setState({reportType: 'VR'})}
            />
          </div> */}
        </div>
        <h3>{this.state.foundDrafts?.length ? `Found ${this.state.foundDrafts.length} drafts` : ''}</h3>
        <div class="customer-drafts-container">
          {this.state.foundDrafts?.map(d => <ReportDraft draft={d} delete={
            () => this.setState({foundDrafts: this.state.foundDrafts.filter(d2 => d2.id !== d.id)})
          }/>)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    drafts: state.drafts,
    accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportNew);
