import React from "react";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import ModalEvent from './ModalEvent';

import EventIcon from '@mui/icons-material/Event';
import CreateIcon from '@mui/icons-material/Create';


class AccountView extends React.Component {
  serviceText() {
    let frequency = this.props.account?.frequency;
    let prefix = "Service due";
    
    if (frequency.cooling === frequency.heating) {
      return `${prefix} ${frequency.cooling}`
    } else {
      if (frequency.cooling === null) {
        return `${prefix} ${frequency.heating} during heating`;
      } else if (frequency.heating === null) {
        return `${prefix} ${frequency.cooling} during cooling`;
      }
      return `${prefix} ${frequency.cooling} during cooling, ${frequency.heating} during heating`
    }
  }

  render() {
    let events = this.props.events?.filter(e => e.customer_id === this.props.account?.id);

    return this.props.account ? (
      <div class="account-view-container" onClick={(e) => e.stopPropagation()}>
        <span class="heading">
          <span>Click a date to schedule service for </span> &nbsp; <b> {this.props.account.name}</b>
          <div class="heading-line" />
        </span>
        
        {events.length ? events.map(e => (
          <span>Scheduled on {e.scheduled_on}</span>
        )) : 
          <span class="due"><b>
            {this.props.account.due > 0 ? "Due for service" : null}
          </b></span>
        }

        <span>Service frequency: <b>{this.serviceText()}</b></span>

        {/* {this.props.account.lastServiced ? (
          <span>Last serviced: {this.props.account.lastServiced}</span>
        ) : null} */}

      </div>
    ) : null
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    events: state.events
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);
