import React from "react";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import ModalEvent from './ModalEvent';
// import MapView from "../lookup/MapView";


class DayViewModal extends React.Component {
  componentDidMount() {
    this.props.fetchAccounts(this.props.authToken);
  }

  deleteEvent(clickEvent, scheduleEvent) {
    console.log(clickEvent);
    clickEvent.stopPropagation();
    this.props.destroyEvent(this.props.authToken, scheduleEvent.id);
  }

  suggestedEvents() {
    if (!this.props.events.length) {return []}
    // let coordinateCollection = this.props.events.map(addresses => addresses?.map(addr => ({lat: addr.latitude, lon: addr.longitude})));
    let coordinatesCollection = this.props.events.map(event => event.location);
    let avgLat = coordinatesCollection.map(loc => loc.latitude).reduce((a, b) => a + b) / coordinatesCollection.length;
    let avgLon = coordinatesCollection.map(loc => loc.longitude).reduce((a, b) => a + b) / coordinatesCollection.length;
    let scheduledCollection = this.props.events.map(event => event.customer_id);
    let pins = this.props.accounts.filter(acc => !scheduledCollection.includes(acc.id)).map(account => ({
      id: account.id,
      name: account.name,
      distance: this.calcDistance(avgLat, avgLon, account.location.latitude, account.location.longitude),
      address: account.location
    }));

    //sort and return 3 closest
    pins = pins.sort((a,b) => a.distance - b.distance).slice(0,3)

    return pins;
  }

  calcDistance(lat1, lon1, lat2, lon2) { 
    const radius = 3959; // radius of the Earth in miles
    const lat1Rad = lat1 * Math.PI / 180;
    const lon1Rad = lon1 * Math.PI / 180;
    const lat2Rad = lat2 * Math.PI / 180;
    const lon2Rad = lon2 * Math.PI / 180;
    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;
    const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;
    const distance = 2 * radius * Math.asin(Math.sqrt(a));
    return distance;  
  }

  addEvent(id) {
    this.props.newEvent(this.props.authToken, {
      customer: id, 
      date: this.props.daySelected
    }, null)
  }

  render() {
    return this.props.daySelected ? (
      <div class="modal-backdrop" onClick={() => this.props.close()}>
        <div class="modal-container" onClick={(e) => e.stopPropagation()}>
          <span class="heading">
            {this.props.daySelected.toDateString()}
            <div class="heading-line" />
          </span>

          <div class="map-container">

          </div>

          {this.props.events.map(event => (
            <ModalEvent event={event} />
          ))}
          
          <div className="suggested-events">
            {this.props.events.length ? (
              <span className="suggested-header">Schedule nearby accounts</span>
            ) : (
              <span className="suggested-header">No accounts scheduled</span>
            )}

            {this.suggestedEvents().map(account => {
              return <button className="suggestion" onClick={() => this.addEvent(account.id)}>
                Add {account.name} ({Math.round(account.distance)} miles)
              </button>
            })}
          </div>
        </div>
      </div>
    ) : null
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DayViewModal);
