import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import 'materialize-css';
import '../styles/lookup.scss';
import Search from './Search';
import AccountInfo from './AccountInfo';
import ProductInfo from './ProductInfo';
// import Nav from '../components/Nav';

import preventRoboto from '../PreventRoboto';
//prevent react google maps from loading new roboto font on map load

import Autocomplete from '../components/Autocomplete';

class LookupMain extends React.Component {

  constructor() {
    super();

    this.state = {
      searchList: []
    }
  }

  componentDidMount() {
    preventRoboto();

    this.props.showNav(true);

    this.props.fetchAccounts(this.props.authToken, () => {
      this.props.fetchProducts(this.props.authToken, 'all', () => {
        this.setState({searchList: this.buildSearchList()});
      });
    });

    // document.getElementById("account-select").focus()
  }

  buildSearchList() {
    let productsList = (this.props.products || []).map(p => ({id: p.id, label: p.model, type: 'p'}));
    let accountsList = (this.props.accounts || []).map(a => ({id: a.id, label: a.name, type: 'a'}));
    return productsList?.concat(accountsList);
  }

  handleOptionSelect(option) {
    this.props.fetchInfo(this.props.authToken, option.id, option.type, null);
  }
  
  render() {
    return (
      <div class="lookup-container">

        <Autocomplete
          list={this.state.searchList}
          onSelect={(option) => this.handleOptionSelect(option)}
          placeholder="Search for an account or product"
          background={true}
        />

        {/* <div className={`lookup-landing ${this.props.info ? 'shrunk' : ''}`}>
          <img className="lookup-icon" src="../information_logo.png" alt="Lookup icon"/>
        </div> */}

        <AccountInfo />
        <ProductInfo />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info,
    pastSearches: state.pastSearches,
    products: state.products,
    accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupMain);
