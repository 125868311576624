import React from 'react';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import '../styles/lookup.scss';
// import 'materialize-css';
import MapView from './MapView';
import ServiceLogModal from './ServiceLogModal';
import Search from './Search';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Checkbox from '@mui/material/Checkbox';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import HvacIcon from '@mui/icons-material/Hvac';
import BookIcon from '@mui/icons-material/Book';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { flexbox } from '@mui/system';


class AccountInfo extends React.Component {

  constructor() {
    super();

    this.state = {
      showServiceLogs: false,
      editContact: null
    };
  }

  newContact() {
    let infoCopy = {...this.props.info};

    let id = Date.now();

    infoCopy.contacts.push({
      id: id, 
      name: '',
      title: '',
      phone: '',
      email: '',
      reports: false,
      primary: false,
      new: true,
      customer: this.props.info.id
    });

    this.props.updateLookupInfo(infoCopy);

    this.setState({editContact: id});
  }

  updateContact(updatedContact) {
    let infoCopy = {...this.props.info};
    
    if (updatedContact.deleted) {
      this.props.freezeInfoUpdate('contact', updatedContact, this.props.authToken, () => {
        infoCopy.contacts = infoCopy.contacts?.filter(c => `${c.id}` !== `${updatedContact.id}`);
        this.props.updateLookupInfo(infoCopy);
      })

    } else {
      infoCopy.contacts = infoCopy.contacts?.map(c => c = c.id === updatedContact.id ? updatedContact : c);
      this.props.updateLookupInfo(infoCopy);
    }
  }

  newReport() {
    this.props.createReport(
      this.props.info.id, 
      'sr', 
      null, 
      this.props.authToken, 
      (newReportUrl) => {window.location.href = `/reports/edit?${newReportUrl}`}
    );
  }

  render() {
    return this.props.info?.type === 'account' ? (
      <div class="info-container">
        <div className='account-header'>
          <div className='account-name'>{this.props.info.name}</div>
          {this.props.info.addresses.length} address{this.props.info.addresses.length > 1 ? 'es' : ''} on file
        </div>

        {this.props.info.addresses ? (
          <div class="map-container info-card">
            <MapView addresses={this.props.info.addresses} equipment={this.props.info.equipment}/>
          </div>
        ) : null}

        {this.props.info.equipment ? (
          <div 
            id="contract-systems" 
            className={`info-card expandable ${this.state.expanded === 'systems' ? 'expanded' : ''}`} 
            onClick={() => this.setState({expanded: 'systems'})}
          >
            <span class="card-header">
              <b>{this.props.info.equipment.length}</b>
              <span>System{this.props.info.equipment.length === 1 ? '' : 's'} under contract</span>
              <HvacIcon className="card-icon"/>
            </span>

            <div class="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {this.props.info.equipment.map((row) => (
                      <TableRow
                        key={'system-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">
                          <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flexDirection: 'column', marginRight: 5}}>
                              <span><b>{row.description}</b></span>
                              <span>Type: <b>{row.type}</b></span>
                            </div> 
                            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                              <span style={{textAlign: 'end'}}>Serviced <b>{row.frequency}</b></span>       
                            </div>
                          </div>
                        </TableCell>
                        {/* <TableCell align='right'>Serviced <b>{row.frequency}</b></TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : null}

        {this.props.info.reports ? (
          <div id="submitted-reports" class="info-card expandable">
            <span class="card-header">
              <b>{this.props.info.reports.length}</b>
              <span>
                Report{this.props.info.reports.length === 1 ? '' : 's'} submitted this year
              </span>

              <ListAltIcon className="card-icon" />
            </span>

            <div class="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="last 10 reports">
                  <TableBody>
                    {this.props.info.reports.reverse().map((report) => (
                      <TableRow
                        key={'report-'+report.url}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => window.open(report.url, '_blank', 'noopener,noreferrer')}
                      >
                        <TableCell align='left'><b>{report.title}</b></TableCell>
                        {report.submitted ? (
                          <TableCell align='right'>Submitted: <b>{report.created}</b></TableCell>
                        ) : (
                          <TableCell align='right'><b>** DRAFT **</b></TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='add-new' onClick={() => this.newReport()}>
                <AddIcon style={{color: '#0D74FF', marginRight: 50, zIndex: 1}}/>
                <span>Create New Report</span>
              </div>
            </div>
          </div>      
        ) : null}
        
        {this.props.info.products ? (
          <div id="products-ordered" class="info-card expandable">
            <span class="card-header">
              <b>{this.props.info.products.length}</b>
              <span>
                Product{this.props.info.reports.length === 1 ? '' : 's'} used
              </span>

              <ShoppingCartIcon className="card-icon" />
            </span>

            <div class="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">

                  <TableBody>
                    {this.props.info.products.map((row) => (
                      <TableRow
                        key={'product-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align='center' size='medium'><b>{row.model}</b></TableCell>
                        <TableCell align='left' sx={{width: 700}}>{row.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : null}
        
        {this.props.info.orders ? (
          <div id="orders-submitted" class="info-card expandable">
            <span class="card-header">
              <b>{this.props.info.orders.length}</b>
              <span>
                Order{this.props.info.reports.length === 1 ? '' : 's'} placed since {new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString()}
              </span>

              <ShoppingCartIcon className="card-icon" />
            </span>

            <div class="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">

                  <TableBody>
                    {this.props.info.orders.map((row) => (
                      <TableRow
                        key={'order-'+row.url}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => window.open(row.url, '_blank', 'noopener,noreferrer')}
                      >
                        <TableCell align='left'>Placed by: <b>{row.placed_by}</b></TableCell>
                        <TableCell align='center'>Items ordered: <b>{row.items}</b></TableCell>
                        <TableCell align='right'>Submitted: <b>{row.submitted}</b></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : null}
        
        {this.props.info.contacts ? (
          <div id="account-contacts" class="info-card expandable">
            <span class="card-header">
              <b>{this.props.info.contacts.length}</b>
              <span>
                Account contact{this.props.info.reports.length === 1 ? '' : 's'}
              </span>
              
              {/* <AddIcon className="card-icon" style={{color: '#0D74FF', marginRight: 50, zIndex: 1}} onClick={() => this.newContact()}/> */}
              <ContactMailIcon className="card-icon" />
            </span>

            <div class="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">

                  <TableBody>
                    {this.props.info.contacts.map((row) => (
                      <TableRow
                        key={'contact-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align='left' onClick={() => this.setState({editContact: row.id})}>
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            {this.state.editContact === row.id ? (
                              <div>
                                <input value={row.name || ''} placeholder="Name" onChange={(e) => {
                                  this.updateContact({...row, name: e.target.value})
                                }}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Name: </span>
                                <b>{row.name}</b>
                              </div>
                            )}
                            {this.state.editContact === row.id ? (
                              <div>
                                <input value={row.title || ''} placeholder="Title" onChange={(e) => {
                                  this.updateContact({...row, title: e.target.value})
                                }}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Title: </span>
                                <b>{row.title}</b>
                              </div>
                            )}
                          </div>
                          
                        </TableCell>

                        <TableCell align='left' onClick={() => this.setState({editContact: row.id})}>
                          <div class="cell-compartment">
                            {this.state.editContact === row.id ? (
                              <div>
                                <input value={row.email || ''} placeholder="Email" onChange={(e) => {
                                  this.updateContact({...row, email: e.target.value})
                                }}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Email: </span>
                                <b>{row.email}</b>
                              </div>
                            )}
                            
                            {this.state.editContact === row.id ? (
                              <div>
                                <input value={row.phone || ''} placeholder="Phone" onChange={(e) => {
                                  this.updateContact({...row, phone: e.target.value})
                                }}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Phone: </span>
                                <b>{row.phone}</b>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        
                        {this.state.editContact !== row.id ? (
                          <TableCell align='right'>
                            <div className="cell-compartment">
                              <div>
                                {/* {row.phone ? (
                                  <a href={`tel:${row.phone}`}>
                                    <PhoneIcon/>
                                  </a>
                                ) : null} */}

                                {row.email ? (
                                  <a href={`mailto:${row.email}`}>
                                    <EmailIcon />
                                  </a>
                                ) : null}
                              </div>
                            </div>
                          </TableCell>
                        ) : null}
                      
                        {this.state.editContact === row.id ? (
                          <TableCell align='right' >
                            <div className="cell-compartment">
                              <DoneIcon className="cell-icon" onClick={
                                () => this.props.freezeInfoUpdate('contact', row, this.props.authToken, () => {
                                  this.setState({editContact: null})
                                })
                              }/>

                              <DeleteIcon className="cell-icon" style={{marginTop: 20}} onClick={() => this.updateContact({...row, deleted: true})}/>
                            </div>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}

                    

                  </TableBody>
                </Table>
              </TableContainer>
              
              <div className='add-new' onClick={() => this.newContact()}>
                <AddIcon style={{color: '#0D74FF', marginRight: 50, zIndex: 1}}/>
                <span>Add new contact</span>
              </div>
            </div>
          </div>
        ) : null}
        
        {this.props.info.contract ? (
          <a href={this.props.info.contract} class="button view-contract" target="_blank" rel="noopener noreferrer">
            <span class="card-header">
              <b>View Contract</b>
              <DescriptionIcon className="button-icon" />
            </span>
          </a>
        ) : null}
        
        {this.props.info.serviceLog ? (
          <div class="button view-log" onClick={() => this.setState({showServiceLogs: true})}>
            <span class="card-header">
              <b>View Service Log</b>
              <BookIcon className="button-icon" />
            </span>
          </div>
        ) : null}


        <ServiceLogModal 
          shown={this.state.showServiceLogs} 
          logs={this.props.info.serviceLog} 
          close={() => this.setState({showServiceLogs: false})}
        />
      </div>
    ) : null
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
