import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import { Checkbox } from 'react-materialize';
import 'materialize-css';
import '../../styles/report.scss';

import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';


class PreSubmit extends React.Component {

  pullTests(comp) {
    if (!comp.tests) {return []};

    return Object.keys(comp.tests).map(id => comp.tests[id]).filter(t => t.type === 'test');
  }

  outOfRange(test) {
    return test.result && (
      (test.max && test.result > test.max) || 
      (test.min && test.result < test.min)
    );
  }

  recipient(email) {
    this.props.updateReport({
      id: this.props.report.id,
      recipients: [...this.props.report.recipients].filter(r => r !== email)
    }, {fridge: true, freezer: this.props.authToken}, null)
  }

  countComponentNotes(comp) {
    if (!comp.notes) {return 0}
    // since notes can exist under components AND under tests, we count them all
    let compNotes = Object.keys(comp.notes).length;
    let testNotes = Object.keys(comp.tests).filter(tid => comp.tests[tid].note?.length).length;

    return compNotes + testNotes;
  }

  render() {
    let comps = this.props.report.components;

    return (
      <div className={`report-modal`} onClick={() => this.props.close()}>
        <form className="onsite-form">
          <div>
            <span>On site: </span>&nbsp;
            <input id="onsite-date" className="picker-input datepicker" value={this.props.report.date_of_visit}></input>
          </div>

          <div>
            <input 
              id="onsite-start" 
              className="picker-input timepicker" 
              value={this.props.report.onsite_start}
            /> 
            &nbsp;—&nbsp;
            <input 
              id="onsite-end"
              className="picker-input timepicker" 
              value={this.props.report.onsite_end} 
              placeholder=" "
            /> 
          </div>
        </form>

        <div class="presubmit-container" onClick={(e) => e.stopPropagation()}>
          <span class="category-header report-summary">Report Summary</span>
          
          {Object.keys(comps).map(id => (
            (id === 'general' || id === '-1') ? null : (
              <div class="component-summary">
                <span class="component-name">{comps[id].name}</span>
                <div class="component-info">
                  <span>Test results</span>
                  <span class="spacer"></span>
                  <div class="summary">
                    <span>{this.pullTests(comps[id]).filter(t => t.result).length} completed</span>
                    {this.pullTests(comps[id]).filter(t => this.outOfRange(t)).length ? (
                      <span class="attention">{this.pullTests(comps[id]).filter(t => this.outOfRange(t)).length} out of range</span>
                    ) : null}
                  </div>
                </div>
                <div class="component-info">
                  <span>Notes</span>
                  <span class="spacer"></span>
                  <div class="summary">{this.countComponentNotes(comps[id])} written</div>
                </div>
              </div>
            )
          ))}
          
          <span class="category-header">Report will be sent to:</span>
          <div class="recipients-container">
            {this.props.report.recipients.map(email => (
              <div class="recipient">
                <span>{email}</span>
                <span class="spacer"></span>
                <span class="remove-recipient" onClick={() => this.recipient(email)}>Remove</span>
              </div>
            ))}
            <button className="add-recipient" onClick={() => this.props.triggerRecipientSelect()}>
              <AddIcon />
              Add Recipient
            </button>
          </div>
        </div>

        <button class="final-submit" onClick={() => 
          this.props.submitReport(this.props.report, this.props.authToken, () => this.props.onSuccess())
        }>
          Submit Report
        </button>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PreSubmit);
