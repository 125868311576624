import { useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";

export function useFuse(searchTerm, items, options = {keys: ['label', 'desc']}) {
  const fuse = useRef();
  const [suggestions, setSuggestions] = useState([]);
  
  useEffect(() => {
    fuse.current = new Fuse(items, options);
  }, [items, options]);

  useEffect(() => {
    console.log('_FUSE+________', fuse, items)
    const results = fuse.current.search(searchTerm);
    setSuggestions(results.map(({ item }) => item));
  }, [searchTerm, items]);

  return suggestions;
}