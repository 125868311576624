import React from 'react';
import './styles/login.scss';
import { Button } from 'react-materialize';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from './redux/actions'; //Import your actions

import { login, logout } from './AuthService';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      password: null,
    }

    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.props.showNav(false);

    this.context = this.canvas.current.getContext('2d');
    this.wave();

    let submit = () => {
      this.props.fetchSession(this.state.username, this.state.password)
    };

    document.addEventListener('keypress', function (e) {
      if (e.key === 'Enter') {
        submit()
      }
    });
  }

  componentWillUnmount() {
    this.props.showNav(true);
  }

  requestSession() {
    let u = document.querySelectorAll('#username-input')[0].value;
    let p = document.querySelectorAll('#password-input')[0].value;
    this.props.fetchSession(u, p);
  }

  wave() {
    let c=this.canvas.current;
    let x=this.context;
    let w=c.width
    let t=0;

    function u(t) {
      c.width = 1920;
      for(var X=w;X--;) {
        x.fillStyle='#0ac5e6'
        x.fillRect(X,0,5,(4-Math.sin((X+t*500)*4/w))*60)
      }
    }

    setInterval(() => {
      u(t)
      t+=0.005
    }, 5)
  }

  handleInput(event) {
    return event.target.value;
  }

  // render() {
  //   return (
  //     <body class="login">
  //       <div class="wavespacer"></div>
  //       <canvas ref={this.canvas} class="wave-canvas" width="2000" height="400"></canvas>
  //       <div class="content-container">
  //         <span class="header">Please log in to continue</span>
  //         {/* <img class="logo" src="./PROASYS_O.png" /> */}
  //         <div class="form-container">
  //           <input
  //             autoFocus
  //             id="username-input"
  //             class="login-input"
  //             type="text"
  //             placeholder="Username"
  //             onChange={(event) => this.setState({username: this.handleInput(event)})}
  //             value={this.props.user?.username}
  //           />

  //           <input
  //             id="password-input"
  //             class="login-input"
  //             type="password"
  //             placeholder="Password"
  //             onChange={(event) => this.setState({password: this.handleInput(event)})}
  //           />

  //           <input type="submit" style={{display: 'none'}} />

  //           <Button
  //             className="submit-button"
  //             onClick={() => this.requestSession()}
  //           >
  //             Log in
  //           </Button>
  //         </div>
  //       </div>

  //       <img class="wave-logo" src="/wave_icon.png" alt="wave logo"/>
  //       <img class="wave-logo proasys" src="/proasys_alt.png" alt="proasys logo"/>
  //     </body>
  //   )
  // }

async handleTokenAcquisition() {
  const authToken = await login()
  this.props.fetchMSSession(authToken.accessToken);
}

  render() {
    return (
      <body class="login">
        <div class="wavespacer"></div>
        <canvas ref={this.canvas} class="wave-canvas" width="2000" height="400"></canvas>
        <div class="content-container">
          <span class="header">Please sign in to continue</span>
          {/* <img class="logo" src="./PROASYS_O.png" /> */}
          <div class="form-container">
            <input
              autoFocus
              id="username-input"
              class="login-input"
              type="text"
              placeholder="Username"
              onChange={(event) => this.setState({username: this.handleInput(event)})}
              value={this.props.user?.username}
            />

            <input
              id="password-input"
              class="login-input"
              type="password"
              placeholder="Password"
              onChange={(event) => this.setState({password: this.handleInput(event)})}
            />

            <input type="submit" style={{display: 'none'}} />

            <Button
              className="submit-button"
              onClick={() => this.requestSession()}
            >
              Log in
            </Button>
          </div>

          <div className='ms-signin-container'>
            <button className="login-button" onClick={() => this.handleTokenAcquisition()}>
              <img src="/ms_signin.svg" />
            </button>
          </div>

        </div>
        <img class="wave-logo" src="/wave_icon.png" alt="wave logo"/>
        <img class="wave-logo proasys" src="/proasys_alt.png" alt="proasys logo"/>
      </body>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    loading: state.loading,
    user: state.user,
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
