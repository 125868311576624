import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import '../styles/lookup.scss';

import CloseIcon from '@mui/icons-material/Close';

const containerStyle = {
  width: '100%',
  height: '100%'
};

function MapsComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAycfeEVduEWMYSL2u_ujkAbUywvy3qcSY"
  })

  const [map, setMap] = React.useState(null)
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(15)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function locateCentroid(locations) {
    if (locations.length === 0) {
      // return a default location object
      return {
        lat: 40.300929,
        lng: -75.9799449
      };
    }
  
    // Calculate the average latitude and longitude values
    const latAvg = locations.reduce((total, loc) => total + loc.latitude, 0) / locations.length;
    const lngAvg = locations.reduce((total, loc) => total + loc.longitude, 0) / locations.length;
  
    // return the centroid object with the average latitude and longitude values
    return { lat: latAvg, lng: lngAvg };
    // return [0,0]
  }

  function formatAddressString(addr) {
    return `${addr.street}, ${addr.city}, ${addr.state} ${addr.zip}`
  }

  function equipmentAtLocation(address) {
    return props.equipment.filter(e => e.address.id === address.id)
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={locateCentroid(props.addresses)}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{scrollwheel: false}}
    >
      {props.addresses.map(a => (
        <Marker 
          position={{ lat: a.latitude, lng: a.longitude }} 
          onClick={() => setSelectedAddress(a)}
        />
      ))}
      {selectedAddress ? (
        <div className={`address-info-background`} onClick={() => setSelectedAddress(null)}>
          <div className='address-info-container'>
            <button className='close' onClick={() => setSelectedAddress(null)}> <CloseIcon/> </button>
            <span className='address'>{formatAddressString(selectedAddress)}</span>
            {
              selectedAddress.description.length > 0 ? 
              <span className='desc'>{selectedAddress.description}</span> 
              : null
            }
    
            <a target='_noreferrer' href={`https://www.google.com/maps/search/?api=1&query=${formatAddressString(selectedAddress)}`} className="maps-link">
              Open in Maps
            </a>

            <span className='system-count'>{equipmentAtLocation(selectedAddress).length} systems on site</span>
            <div className='equipment-container'>
            </div>
          </div>
        </div>
      ) : null}
    </GoogleMap>
  ) : <></>
}

export default React.memo(MapsComponent)