import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import { Checkbox } from 'react-materialize';
import 'materialize-css';
import '../../styles/report.scss';

import CheckIcon from '@mui/icons-material/Check';


class RecipientSelect extends React.Component {

  constructor() {
    super();

    this.state = {
      recipientInput: ''
    };
  }

  handleContactClick(e, email) {
    e.stopPropagation();

    let recipients = [...this.props.report.recipients];
    
    if (recipients?.includes(email)) {
      recipients = recipients.filter(r => r !== email);
      console.log('removed', email, 'from recipients');
    } else {
      recipients.push(email);
      console.log('added', email);
    }

    this.props.updateReport(
      {id: this.props.report.id, recipients: recipients}, 
      {fridge: true, freezer: this.props.authToken}, 
      null
    )
  }

  mergeContacts() {
    let recipients = this.props.report.recipients;
    let contacts = this.props.report.contacts.map(c => c.email);

    let merged = [...contacts];

    for (var i=0; i < recipients?.length; i++) {
      if (!merged.includes(recipients[i])) {
        merged.push(recipients[i]);
      }
    }
    
    return merged;
  }

  validateEmail(str) {
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return reg.test(str);
  }

  render() {
    return (
      <div class={`report-modal`} onClick={() => this.props.close()}>
        <div class="recipient-select-container" onClick={(e) => e.stopPropagation()}>
          {this.mergeContacts().map((email) => (
            <div 
              class={`contact ${this.props.report.recipients?.includes(email) ? 'selected' : ''}`} 
              onClick={(e) => this.handleContactClick(e, email)}
            >
              <div class="checkbox-container">
                <Checkbox checked={this.props.report.recipients?.includes(email)} onClick={e => e.preventDefault()}/>
              </div>
              {email}
            </div>
          ))}

          <div class="recipient-input-container">
            <input 
              class="recipient-input" 
              value={this.state.recipientInput} 
              onChange={(e) => this.setState({recipientInput: e.target.value})} 
              placeholder="Add an email address"
            />

            <button 
              class="submit-recipient" 
              disabled={this.validateEmail(this.state.recipientInput) ? '' : 'disabled'} 
              onClick={(e) => {
                this.handleContactClick(e, this.state.recipientInput);
                this.setState({recipientInput: ''});
              }}
            >
              <CheckIcon />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientSelect);
