import React from 'react';
import ReportTest from '../report/report_components/ReportTest';
import ReportNote from '../report/report_components/ReportNote';

import '../styles/lookup.scss';
import 'materialize-css';


class ServiceLogModal extends React.Component {

  render() {
    return this.props.shown ? (
      <div class="modal-backdrop" onClick={() => this.props.close()}>
        <div class="modal">
          {Object.entries(this.props.logs).reverse().map(date => ([
            <span class="log-date">{date[0]}</span>,
            Object.entries(date[1]).map(node => ([
              node[1].iAm === 'test' ? 
              <ReportTest 
                key={node[1].id} 
                test={node[1]} 
                log={true}
              /> :
              <ReportNote 
                key={node[1].id} 
                node={node[1]} 
                log={true}
              /> 
            ]))
          ]))}
        </div>
      </div>
    ) : null
  }
}
 export default ServiceLogModal;
