import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import DataTable from 'react-data-table-component';

// import
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

class ReportHistory extends React.Component {

  componentDidMount() {
    this.props.fetchReportHistory(this.props.authToken);
  }

  formatRows() {
    return this.props.history?.map(r => ({
      id: r.hash_id,
      title: r.title,
      author: r.author,
      submitted: `${r.created[0]} at ${r.created[1]}`,
      components: r.components,
      inRange: r.inRange,
      recipients: r.recipients
    }))
  }

  formatExpandable(data) {
    // return JSON.stringify(data.components, null, 2)
    if (!data.components.length) {
      return <div class="component-row">No tests performed</div>
    }
    
    return <div class="expanded-content-container">
      <div class="component-summary">
        {data.components.map(c => (
          <div class="component-row">
            {c.inRange ? <CheckCircleOutlineIcon className="component-okay"/> : <ErrorOutlineIcon className="component-attention"/>}
            {c.name}
          </div>
        ))}
      </div>
      <div class="report-recipients">
        Received by:
        {data.recipients?.split(', ').map(r => (<a href={`mailto:${r}`}>{r}</a>))}
      </div>
    </div>
  }

  handleRowClick(data, event) {
    console.log('clicked', data);
    window.location = `/reports/edit?${data.id}`
  }

  render() {
    return (
      <div class="content-container report-history-table">
        <DataTable
          responsive
          columns={[
            {name: 'Title', selector: row => row.title},
            {name: 'Author', selector: row => row.author},
            {name: 'Submitted', selector: row => row.submitted},
            // {name: 'View', selector: row => row.view}
          ]}
          data={this.formatRows(this.props.history)}
          expandableRows
          expandableRowsComponent={
            ({ data }) => <pre>{this.formatExpandable(data)}</pre>
          }
          onRowClicked={(data, event) => this.handleRowClick(data, event)}
          conditionalRowStyles={[
            {
              when: row => row.inRange,
              style: {
                backgroundColor: '#d0ffde'
              }
            },
            {
              when: row => row.inRange === false,
              style: {
                backgroundColor: '#ffe4d1'
              }
            },
            {
              when: row => row.inRange === null,
              style: {
                backgroundColor: 'white'
              }
            }
          ]}
          pagination={true}
        />
        {JSON.stringify(this.props.reportHistory)}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    history: state.reportHistory,
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportHistory);
