import React from "react";
import Calendar from 'react-calendar';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';

import '../styles/schedule.scss';

class Cal extends React.Component {

  filterEventsByDate(date, view) {
    if (view === 'month') {
      let events = this.props.events?.filter(e => (
        e.scheduled_on === date.toISOString().slice(0,10)
      ));

      // console.log('events on', date, events);

      return events?.map(e => (
        <span className={`event pill ${e.status}`}>{e.title[0]}</span>
      ))
    }
  }

  render() {
    // console.log('rendering calendar with events', this.props.events, 'daySelected', this.props.daySelected)
    return (
      <Calendar 
        calendarType="US"
        tileContent={({ activeStartDate, date, view }) => this.filterEventsByDate(date, view)}
        onClickDay={(value) => this.props.dayClick(value)}
        activeStartDate={this.props.calendarDate}
        onActiveStartDateChange={(action) => this.props.onChangeCalendar(action.activeStartDate)}
      />
    )
  }
}

function mapStateToProps(state, props) {
  return {
    accountList: state.accountList,
    accounts: state.accounts,
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Cal);
