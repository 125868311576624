import React from 'react';

export default class ScheduleIcon extends React.Component {
  render() {
    return <svg class={this.props.className} id="schedule-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.74 223.53"><path d="M33.51,23.16c0-7.91,0-14.99,0-22.38c7.52,0,14.58,0,22.14,0c0,7.27,0,14.47,0,21.97c29.94,0,59.39,0,89.33,0
      c0-7.29,0-14.36,0-21.86c7.67,0,14.86,0,22.61,0c0,7.23,0,14.43,0,22.19c4.14,0,7.74-0.02,11.35,0c12.38,0.08,21.8,9.37,21.8,21.62
      c0.02,52.47,0.01,104.94,0,157.42c0,11.8-8.9,21.27-20.81,21.3c-52.97,0.15-105.94,0.14-158.91,0.01
      c-11.27-0.03-19.93-8.7-20.82-19.96c-0.13-1.65-0.18-3.32-0.18-4.98C0.01,148.34-0.01,98.2,0.01,48.05
      c0.01-13.73,7.2-23.39,18.8-24.8C23.37,22.69,28.05,23.16,33.51,23.16z M22.64,78.98c0,41.12,0,81.69,0,122.23
      c52.18,0,104.03,0,155.84,0c0-40.94,0-81.51,0-122.23C126.41,78.98,74.68,78.98,22.64,78.98z"/>
      <path d="M65.96,188.94c3.61-10.98,7.06-22.01,10.91-32.91c1.17-3.32,0.48-4.97-2.32-6.89c-9.35-6.44-18.51-13.16-27.44-20.8
      c10.37,0,20.77-0.39,31.11,0.15c5.85,0.31,8.33-1.49,9.86-7.21c2.68-10.04,6.4-19.8,10.13-31.03c3.73,11.38,7.49,21.42,10.22,31.74
      c1.43,5.39,3.76,6.92,9.13,6.69c10.43-0.46,20.89-0.13,32.16-0.13c-1.32,1.24-2.09,2.15-3.03,2.83
      c-8.19,5.91-16.33,11.89-24.65,17.61c-2.87,1.98-3.83,3.69-2.54,7.31c3.79,10.65,7.06,21.48,10.98,33.63
      c-8.99-6.55-16.91-12.23-24.74-18.03c-7.59-5.62-7.53-5.63-15.02-0.03c-7.94,5.94-15.95,11.78-23.94,17.67
      C66.51,189.34,66.23,189.14,65.96,188.94z"/>
    </svg>
  }
}