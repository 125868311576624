import React from "react";
// import Nav from '../components/Nav';
import Cal from './Calendar';

import { Select } from 'react-materialize';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import DayViewModal from "./DayViewModal";
import AccountView from "./AccountSelectedView";


class Main extends React.Component {
  constructor() {
    super();
    let date = new Date();
    var newDate = new Date(date.setMonth(date.getMonth()));

    this.state = {
      calendarDate: newDate,
      employeeSelected: null, // supplied as "##"; will be null unless user is a regional manager and is viewing a henchman's calendar
      daySelected: null,
      accountSelected: null, 
      accountsShown: true,
      accountsToggle: 'serviceableAccounts',
      mousePos: {x:0, y:0},
      // deltaPos: {x:0, y:0}
    };

    window.addEventListener('mousemove', this.handleMouseMove.bind(this));
    window.addEventListener('mousedown', (e) => {
      if (this.state.accountSelected) { // if account selected
        if (e.target.className.includes('react-calendar__tile')) { // if calendar tile is clicked
          return
        } else {
          this.setState({accountSelected: null, mousePos: {x: 0, y:0}})
        }
      }
    });
  }

  componentDidMount() {
    this.props.fetchCalendar(this.props.authToken, new Date().toISOString().slice(0,7), null, () => {
      console.log('fetched events', this.props.events);
    })

    this.props.showNav(true);
  }

  handleDayClick(value) {
    if (this.state.accountSelected) {
      this.props.newEvent(this.props.authToken, {
        customer: this.state.accountSelected.id, 
        date: value.toISOString().slice(0,10)
      }, () => this.setState({daySelected: value, accountSelected: null}))
    } else {
      this.setState({daySelected: value});
    }
  }

  handleAccountButtonClick(event, account) {
    if (this.state.employeeSelected && (`${this.props.user.employee.id}` !== `${this.state.employeeSelected}`)) {return false}

    if (account.id === this.state.accountSelected?.id) {
      this.setState({
        accountSelected: null,
        accountsShown: true,
      })
    } else {
      this.setState({
        accountSelected: account, 
        accountsShown: false, 
        mousePos: {x: event.clientX-60, y: event.clientY-10}
      });
    }
  }

  handleMouseMove(e) {
    if (this.state.accountSelected) {
      this.setState({mousePos: {x: e.clientX-60, y: e.clientY-10}});
    } else { return }
  }

  sortAccounts() {
    let unscheduled = [];
    let scheduled = [];

    this.props[this.state.accountsToggle]?.forEach( a => {
      a.events = this.matchEventsToAccount(a.id);
      a.events.length ? scheduled.push(a) : unscheduled.push(a);
    })

    console.log('scheduled', scheduled);

    return [unscheduled, scheduled];
  }

  handleCalendarChange(date) {
    if (date.getMonth() === this.state.calendarDate.getMonth() && date.getYear() === this.state.calendarDate.getYear()) {
      return
    }
    
    this.setState({calendarDate: date, accountSelected: null, daySelected:null});

    this.props.fetchCalendar(this.props.authToken, date.toISOString().slice(0,7), this.state.employeeSelected, () => {
      console.log('fetched events', this.props.events);
    })
  }

  matchEventsToAccount(id) {
    // returns all events for the displayed month
    return this.props.events.filter(e => e.customer_id === id && parseInt(e.scheduled_on.split('-')[1]) === this.state.calendarDate.getMonth()+1);
  }

  accountStatus(id) {
    // iterates through all matching events and returns one of the following based on their hierarchy
    let options = ['optional', 'completed', 'unfinished', 'overdue'];
    let status = "optional";
    let matchingEvents = this.matchEventsToAccount(id);
    for (var i = 0; i < matchingEvents.length; i++) {
      if (options.includes(matchingEvents[i].status)) {
        if (options.indexOf(matchingEvents[i].status) > options.indexOf(status)) {
          status = matchingEvents[i].status
        }
      }
    }
    
    return status;
  }

  serviceFrequency(account) {
    if (account.frequency['cooling'] === account.frequency['heating']) {
      return `Due ${account.frequency['cooling']}`; 
    } else {
      let activeSeason = [4,5,6,7,8,9].includes(this.state.calendarDate.getMonth()+1) ? 'cooling' : 'heating';
      return `Due ${account.frequency[activeSeason]} during ${activeSeason}`;
    }
  }

  render() {
    let [unscheduled, scheduled] = this.sortAccounts();

    return (
      <div className={`schedule-container ${this.state.accountSelected ? 'dark' : ''}`}>
        {/* {JSON.stringify(this.state)} */} 
             
        <div class="calendar-container">
          
          {this.props.user?.managing?.length > 0 ? (
            <div className="henchmen-select-container">
              <span>Viewing calendar as</span>
              <Select
                id="henchmen-select"
                onChange={(e) => this.props.fetchCalendar(
                  this.props.authToken, 
                  this.state.calendarDate.toISOString().slice(0,7), 
                  e.target.value,
                  () => this.setState({employeeSelected: e.target.value})
                )}
                multiple={false}
                value={this.props.user.id}
                options={{
                  dropdownOptions: {
                    coverTrigger: false
                  }
                }}
              >
                {this.props.user.managing.map(employee => (
                  <option value={employee.id}>{employee.name}</option>
                ))}
              </Select>
            </div>
          ) : null}

          <Cal
            events={this.props.events}
            calendarDate={this.state.calendarDate}
            daySelected={this.state.daySelected}
            dayClick={(date) => this.handleDayClick(date)}
            onChangeCalendar={(date) => this.handleCalendarChange(date)}
          />
        </div>

        <div class="accounts-container">
          {this.props.managedAccounts?.length ? (
            <div className="accounts-toggle">
              <button className={`${this.state.accountsToggle === 'serviceableAccounts' ? 'active' : null}`} onClick={() => this.setState({accountsToggle: 'serviceableAccounts'})}>Serviced Accounts</button>
              <button className={`${this.state.accountsToggle === 'managedAccounts' ? 'active' : null}`} onClick={() => this.setState({accountsToggle: 'managedAccounts'})}>Managed Accounts</button>
            </div>
          ) : null}

          <div className="reactive-container">
            <div class="unscheduled-accounts category">
              <button class="content-toggle">Unscheduled</button>
              <div class="content">
                {unscheduled.map(account => ( 
                  <button 
                    className={`account-button ${this.state.accountSelected && this.state.accountSelected?.id !== account.id ? 'dimmed' : ''}`}
                    style={account.id === this.state.accountSelected?.id ? {position: 'unset'} : {position: 'relative'}}
                    onClick={(e) => this.handleAccountButtonClick(e, account)}
                  >
                    <button
                      className={`indicator pill`}
                      style={this.state.accountSelected?.id === account.id ? {transform: `translate(${this.state.mousePos.x}px, ${this.state.mousePos.y}px)`} : null}
                    >
                      {account.name[0]}
                    </button>
                    <div className="account-name pill"><p>{account.name}</p></div>
                    <span className="svc-frequency">{this.serviceFrequency(account)}</span>
                    <div className="pill-box">
                      {account.due === 'first' ? <span className="pill first">First</span> : null}
                      {account.servicedBy.map(e => (
                        <span className="pill service-tech">{e}</span>
                      ))}
                    </div>
                  </button>
                ))}     
              </div>
            </div>

            <div class="scheduled-accounts category">
              <button class="content-toggle">Scheduled</button>
              <div class="content">
                {scheduled.map(account => (
                  <button
                    className={`account-button ${this.state.accountSelected?.id === account.id ? 'clicked' : ''}`}
                    onClick={(e) => this.handleAccountButtonClick(e, account)}
                  >
                    {/* <div className={`indicator pill ${this.accountStatus(account.id)}`}>{account.name[0]}</div> */}
                    <div className={`account-name pill ${this.accountStatus(account.id)}`}><p>{account.name}</p></div>
                    <span className="svc-frequency">{this.serviceFrequency(account)}</span>
                    <div className="pill-box">
                      {this.matchEventsToAccount(account.id).map(e => (
                        <span className={`pill ${e.status}`}>
                          {e.scheduled_on.split('-').splice(1,2).map(d => parseInt(d)).join('/')}
                        </span>
                      ))}
                    </div>

                  </button>
                ))}
              </div>
            </div>
          </div>

          
          {/* {this.props.accounts?.map(account => (
            <button 
              class="account-button"
              class={`accounts-container`}
              onClick={() => this.setState({accountSelected: account, accountsShown: false})}
            >
              <div class="indicator" />
              {account.name}
            </button>
          ))} */}
        </div>

        <DayViewModal
          events={
            this.props.events?.filter(e => (
              e.scheduled_on === this.state.daySelected?.toISOString().slice(0,10)
            ))
          } 
          accounts={this.state.accountsToggle === 'serviceableAccounts' ? this.props.serviceableAccounts : this.props.managedAccounts}
          daySelected={this.state.daySelected}
          close={() => this.setState({daySelected: null})}
        />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    events: state.events,
    accounts: state.accountList,
    serviceableAccounts: state.serviceableAccounts,
    managedAccounts: state.managedAccounts,
    authToken: state.authToken,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);