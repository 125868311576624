import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import ReportNew from './ReportNew';
import ReportDrafts from './ReportDrafts';
import ReportHistory from './ReportHistory';
import ReportEdit from './ReportEdit';
// import Nav from '../components/Nav';

import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';

class ReportDash extends React.Component {
  constructor() {
    super();

    this.state = {
      activeCategory: 'new',
    }
  }

  componentDidMount() {
    this.props.showNav(true);
    // this.props.setNavHeader("Create a Report or Edit a Draft");
  }
  
  activeCategory() {
    switch (this.state.activeCategory) {
      case 'new':
        return <ReportNew />;
      case 'drafts':
        return <ReportDrafts />;
      case 'history':
        return <ReportHistory />;
      default:
        return false;
    }
  }

  headerText() {
    switch (this.state.activeCategory) {
      case 'new':
        return 'Create a new report';
      case 'drafts':
        return 'Resume an unfinished report';
      case 'history':
        return 'View a previous report';
      default:
        return false;
    }
  }

  render() {
    return (
      <body class="report-index" style={{backgroundColor: '#F2F2F4'}}>
        {/* <Nav location="reports" header={this.headerText()} /> */}

        <div class="dash-container">
          <div class="tab-container">

            <div
              class={this.state.activeCategory === 'new' ? 'tab-option selected' : 'tab-option'}
              onClick={() => {this.setState({activeCategory: 'new'})}}
            >
              <div id="new-report" class="flex-column">
                <DescriptionIcon style={{fontSize: 40}}/>
                <span>New Report</span>
              </div>
            </div>
            <div
              class={this.state.activeCategory === 'drafts' ? 'tab-option selected' : 'tab-option'}
              onClick={() => {this.setState({activeCategory: 'drafts'})}}
            >
              <div class="flex-column">
                <EditIcon style={{fontSize: 40}}/>
                <span>View All Drafts</span>
              </div>
            </div>
            <div
              class={this.state.activeCategory === 'history' ? 'tab-option selected' : 'tab-option'}
              onClick={() => {this.setState({activeCategory: 'history'})}}
            >
              <div class="flex-column">
                <HistoryIcon style={{fontSize: 40}}/>
                <span>Report History</span>
              </div>
            </div>
          </div>

          {this.activeCategory()}
        </div>
      </body>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    report: state.report,
    authToken: state.authToken
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDash);
