import React from 'react';

import 'materialize-css';
import '../styles/nav.scss'

import LaunchIcon from '@mui/icons-material/Launch';
import ReportIcon from './ReportIcon';
import LookupIcon from './LookupIcon';
import ScheduleIcon from './ScheduleIcon';
import OrderIcon from './OrderIcon';
import LogoutIcon from '@mui/icons-material/Logout';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';

import { Textfit } from 'react-textfit';


class Nav extends React.Component {
  active(screen) {
    return this.props.location === screen ? 'active' : null;
  }

  render() {
    // console.log('this.props.nav', this.props.nav)
    if (!this.props.nav) {
      return this.props.children;
    } else {
      return (
        <div className={`nav-wrapper ${this.props.location || 'reports'}`}>
          <div className="nav-sidebar">
          <a className={"nav-btn lookup "+this.active('lookup')} href="/lookup">
            <LookupIcon className="nav-icon" />
              Lookup
            </a>
            <a className={"nav-btn reports "+this.active('reports')} href="/reports">
              <ReportIcon className="nav-icon" />
              Reports
            </a>
            <a className={"nav-btn schedule "+this.active('schedule')} href="/schedule">
              <ScheduleIcon className="nav-icon" />
              Schedule
            </a>
            <a className={"nav-btn orders "+this.active('orders')} href="https://www.proasysinc.com/orders" target="_blank" rel="noreferrer">
              <OrderIcon className="nav-icon" />
              Orders
              <LaunchIcon className="external-link"/>
            </a>

            <div className={"spacer"}/>

            <button className={"nav-btn logout"} onClick={() => this.props.logOut()}>
              <LogoutIcon className="nav-icon" />
              Logout
            </button>
          </div>
          <div className="everything-else">
            <div className="nav-header">
              <Textfit mode="single" max={40}>{this.props.navHeader}</Textfit>
            </div>

            <div className="page-wrapper">
              {this.props.children}
            </div>
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state, props) {
  return {
    navHeader: state.navHeader,
    user: state.user,
    clientVersion: state.clientVersion,
    nav: state.showNav
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);