//.....API ENDPOINT (LOCAL / PRODUCTION)
// const ApiUrl = process.env.NODE_ENV === 'development' ? 'http://10.238.103.143:3000' : 'https://api.proasysinc.com';
// const ApiUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.102:3000' : 'http://api.proasysinc.com';
const ApiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://api.proasysinc.com';
// const ApiUrl = 'http://localhost:5000';
// const ApiUrl = 'https://api.proasysinc.com';
//.....................................


export const fetchBegin = () => ({
  type: 'FETCH_BEGIN'
})

export const fetchSuccess = (response) => ({
  type: 'FETCH_SUCCESS',
  response: response
})

export const fetchSessionSuccess = (payload) => ({
  type: 'FETCH_SESSION_SUCCESS',
  payload: payload
})

export const fetchAccountsSuccess = (payload) => ({
  type: 'FETCH_ACCOUNTS_SUCCESS',
  payload: payload
})

export const fetchProductsSuccess = (payload) => ({
  type: 'FETCH_PRODUCTS_SUCCESS',
  payload: payload
})

export const fetchReportSuccess = (report) =>  ({
  type: 'FETCH_REPORT_SUCCESS',
  report: report
})

export const fetchReportDraftsSuccess = (drafts) => ({
  type: 'FETCH_REPORT_DRAFTS_SUCCESS',
  drafts: drafts
})

export const fetchInfoSuccess = (info) => ({
  type: 'FETCH_INFO_SUCCESS',
  info: info
})

export const fetchFailure = (error) => ({
  type: 'FETCH_FAILURE',
  payload: error
})

export const clearSession = () => ({
  type: 'CLEAR_SESSION'
})

// export const createReportSuccess = (payload) => ({
//   type: 'NEW_REPORT',
//   payload: payload
// })

export const deleteReportSuccess = (payload) => ({
  type: 'DELETE_REPORT_SUCCESS',
  payload: payload
})

export const exitReport = () => ({
  type: 'EXIT_REPORT'
})

export const reportSubmitted = (payload) => ({
  type: 'REPORT_SUBMITTED',
  payload: payload
})

export const fetchCalendarSuccess = (payload) => ({
  type: 'FETCH_CALENDAR_SUCCESS',
  payload: payload
})

export const createEvent = (eventData) => ({
  type: 'NEW_EVENT',
  payload: eventData
})

export const destroyEventSuccess = (eventId) => ({
  type: 'DESTROY_EVENT',
  eventId: eventId
})

export const fetchTestHistorySuccess = (component, test, history) => ({
  type: 'LOAD_TEST_HISTORY',
  component: component,
  test: test,
  history: history
})

export const clearError = () => ({
  type: 'CLEAR_ERROR'
})

export const testError = () => ({
  type: 'TEST_ERROR'
})

export const fetchReportHistorySuccess = (reports) => ({
  type: 'REPORT_HISTORY',
  reports: reports
})

export const fetchOrderHistorySuccess = (orders, append) => ({
  type: 'ORDER_HISTORY',
  append: append || null,
  orders: orders
})

export const initialDataLoaded = (state) => ({
  type: 'INITIAL_LOAD',
  state: state
})

export const fetchTestFieldsSuccess = (tests) => ({
  type: 'TEST_FIELDS',
  payload: tests
})

function validateResponseStatus(response) {
  if (response.status === 200) {
    return true;
  } else {
    throw response;
  }
}

export function pingApi() {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/ping`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchMSSession(token, onSuccess) {

  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/newsesh`, { // call to api
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: token
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error}
      dispatch(fetchSessionSuccess(json))
      return json
    })
    // .then(json => onSuccess(json))
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchSession(username, password, onSuccess) {

  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/newsesh`, { // call to api
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error}
      json.user.username = username
      dispatch(fetchSessionSuccess(json))
      return json
    })
    // .then(json => onSuccess(json))
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function refreshSession(token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error}
      dispatch(fetchSessionSuccess({
        ...json,
        auth_token: token
      }))
      return json
    })
    // .then(json => onSuccess(json))
    .catch((error) => {
      dispatch(fetchFailure(error))
      localStorage.removeItem('authToken');
    })
  }
}

export function fetchReport(token, id, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());
    console.log('gimme report', id);

    return fetch(`${ApiUrl}/reports/edit?id=${id}`, {
      method: 'POST',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error};
      dispatch(fetchReportSuccess(json));
      if (onSuccess) {onSuccess()};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchAccounts(token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/customers`, {
      method: 'POST',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error};
      dispatch(fetchAccountsSuccess(json));
      if (onSuccess) {onSuccess()};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchProducts(token, type, onSuccess) {
  console.log('auth_token: ', token);
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/orders/products?type=${type}`, {
      method: 'POST',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error};
      dispatch(fetchProductsSuccess(json));
    })
    .then(() => {
      if (onSuccess) {onSuccess()};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchReportDrafts(customer, token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/reports/drafts`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({customer_id: `${customer}`})
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error};
      dispatch(fetchReportDraftsSuccess(json));
      return json;
    })
    .then((drafts) => {
      console.log('firing big lasers', drafts);
      if (onSuccess) {onSuccess(drafts)};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function logOut() {
  return dispatch => {
    dispatch(clearSession());
  }
}

export function createReport(customer, reportType, eventId, token, onSuccess) {
  console.log(customer, reportType, eventId, token);
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/reports/new?customer=${customer}&type=${reportType}${eventId ? '&event='+eventId : ''}`, {
      method: 'POST',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json.error}
      if (onSuccess) {onSuccess(json.hash_id)};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function updateReport(update, storage, onComplete) {
  console.log('updating report with', update, storage);

  update.last_edit = Date.now();

  return dispatch => {
    dispatch({
      type: 'UPDATE_REPORT',
      payload: update,
      local: storage?.fridge
    });

    if (storage?.freezer) {
      // beam it up to api

      return fetch(`${ApiUrl}/reports/update`, {
        method: 'POST',
        headers: {
          'Authorization': storage.freezer,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          update: update
        })
      })
      .then((response) => {
        if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}

        let json = response.json();
        if (json.error) {throw json};

        return json;
      })
      .then((json) => {
        console.log('recd json', json);
        if (json['status'] === 'saved') {
          onComplete();
        }
      })
      .then(() => {
        console.log('triggered oncomplete');
        if (onComplete) {onComplete()}
      })
      .catch((error) => {
        dispatch(fetchFailure(error))
      })
    } else {
      console.log('triggered oncomplete');
      if (onComplete) {onComplete()}
    }
  }
}

export function submitReport(report, token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/reports/submit`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        report: report
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      // console.log('deleting report response: ', response);
      let json = response.json();
      if (json.error) {throw json};
      return json;
    })
    .then((json) => {
      // console.log('report save response:', response.json());
      console.log('recd json', json);
      if (json['status'] === 'saved') {
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchTestHistory(componentId, testId, token) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/reports/history`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        component: componentId,
        test: testId
      })
    })
    .then(response => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      let json = JSON.parse(response._bodyText);
      dispatch(fetchTestHistorySuccess(componentId, testId, json));
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function quitReport() {
  console.log('closing report');
  return dispatch => {
    dispatch(exitReport())
  }
}

export function destroyReport(id, token) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/reports/destroy`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        report: id
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      // console.log('deleting report response: ', response);
      let json = response.json()
      if (json.error) {throw json}
      return json
    })
    .then((json) => {
      if (json.status === 'deleted') {
        dispatch(deleteReportSuccess(id))
      }
    })
    .then(() => {
      // change associated event in local state
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchCalendar(token, month, employeeID, onSuccess) {
  // month should be supplied as string 'YYYY-MM'
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/schedule/load`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        month: month,
        employee: employeeID
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json}
      dispatch(fetchCalendarSuccess(json));
      if (onSuccess) {onSuccess()};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function newEvent(token, eventData, onSuccess) {
  console.log('firing here with', eventData);
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/schedule/new`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        customer: eventData.customer,
        date: eventData.date,
        time_in: eventData.time_in,
        time_out: eventData.time_out,
        note: eventData.note
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json();
    })
    .then((json) => {
      if (json.error) {throw json}
      if (json.status === "saved") {
        dispatch(createEvent(json.event))
      }
    })
    .then(() => {
      if (onSuccess) {onSuccess()}
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function updateEvent(token, eventData, onSuccess) {
  console.log('sending event update', eventData);
  return dispatch => {
    if (token) {
      dispatch(fetchBegin());

      return fetch(`${ApiUrl}/schedule/update`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(eventData)
      })
      .then((response) => {
        if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
        return response.json();
      })
      .then((json) => {
        if (json.error) {throw json}
      })
      .catch((error) => {
        dispatch(fetchFailure(error));
      })
    } else {
      dispatch({
        type: 'UPDATE_EVENT',
        payload: eventData
      });
    }
    if (onSuccess) {
      onSuccess();
    }
  }
}

export function destroyEvent(token, eventData) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/schedule/delete`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(eventData)
    })
    .then(response => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json();
    })
    .then((response) => {
      if (response.status === 'deleted') {
        console.log(response);
        dispatch(destroyEventSuccess(eventData.id))
      }
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function repeatEvent(token, eventRepeat) {
  
}

export function updateOrder(order) {
  //validate order
  order.valid = (
    order.config?.valid &&
    order.cart?.length &&
    ((order.config?.method === 'Delivery' && order.config?.destination) ||
    (order.config?.method === 'Pickup' && order.config?.pickupDate))
  );
  // order is not a direct reference to stored order so we mutate it

  return dispatch => {
    dispatch({
      type: 'UPDATE_ORDER',
      order: order
    })
  }
}

export function submitOrder(order, token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());
    console.log('submitting with token', token );
    return fetch(`${ApiUrl}/orders/save`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        order: order
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {
        dispatch(fetchSuccess(response));
      } else {
        throw(response)
      }

      return response.json()
    })
    .then(confirmedOrder => {
      onSuccess(confirmedOrder);

      dispatch(fetchOrderHistorySuccess(confirmedOrder, true));
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function submitLog(token, state) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/submit_log`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        'user': state.user.id,
        'state': state.error
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response
    })
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchReportHistory(token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());
    console.log('fetching report history with token ', token);
    return fetch(`${ApiUrl}/reports/history`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        length: 20
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      console.log('report history: ', json);
      if (json.error) {throw json}
      dispatch(fetchReportHistorySuccess(json));
      if (onSuccess) {onSuccess()};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchOrderHistory(token, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());
    console.log('fetching order history with token ', token);
    return fetch(`${ApiUrl}/orders/history`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        length: 20
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      console.log('order history: ', json);
      if (json.error) {throw json}
      dispatch(fetchOrderHistorySuccess(json));
      if (onSuccess) {onSuccess()};
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function fetchInfo(token, id, type, onSuccess) {
  //save searches to pastSearches in reducer

  return dispatch => {
    dispatch(fetchBegin());
    console.log('sending info request with params', type, id);
    return fetch(`${ApiUrl}/info`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        type: type,
        id: id
      })
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json}
      dispatch(fetchInfoSuccess(json))
      if (onSuccess) {onSuccess()}
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function clearInfo() {
  return dispatch => {
    dispatch(({
      type: 'CLEAR_INFO'
    }))
  }
}

export function fetchTestFields(token) {
  return dispatch => {
    dispatch(fetchBegin());
    console.log('fetching report tests');
    return fetch(`${ApiUrl}/reports/tests`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json()
    })
    .then((json) => {
      if (json.error) {throw json}
      dispatch(fetchTestFieldsSuccess(json))
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function testAPIFailure(token) {
  return dispatch => {
    dispatch(fetchBegin());
    return fetch(`${ApiUrl}/testfail`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'status': 404,
        'error': 'u sugg',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function editCompTest(token, test, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());

    return fetch(`${ApiUrl}/reports/edit_test`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(test)
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json();
    })
    .then((response) => {
      console.log(response);

      // server should have returned new template, put it in the fridge
      dispatch({
        type: 'UPDATE_REPORT_TEMPLATE',
        payload: response
      });

      if (onSuccess) {onSuccess();}
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

export function setNavHeader(headerText) {
  return dispatch => {
    dispatch({
      type: 'NAV_HEADER',
      payload: headerText
    });
  }
}

export function showNav(bool) {
  return dispatch => {
    dispatch({
      type: 'SHOW_NAV',
      payload: bool
    });
  }
}

export function updateLookupInfo(info) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_LOOKUP_INFO',
      payload: info
    })
  }
}

export function freezeInfoUpdate(type, update, authToken, onSuccess) {
  return dispatch => {
    dispatch(fetchBegin());
    console.log('shipping info update');
    return fetch(`${ApiUrl}/update_info`, {
      method: 'POST',
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({[type]: update})
    })
    .then((response) => {
      if (validateResponseStatus(response)) {dispatch(fetchSuccess(response))}
      return response.json();
    })
    .then((response) => {
      console.log('received update info response', response);
      if (onSuccess) {onSuccess();}
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
    })
  }
}

// export function fetchOfflineReports(accounts, token) {
//   return dispatch => {
//     dispatch{fetchBegin()}
//
//     return fetch(`${ApiUrl}/reports/`)
//   }
// }
