import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions'; //Import your actions
import TextareaAutosize from 'react-textarea-autosize';
import ReportTestHistory from './ReportTestHistory.js'
import '../../styles/report.scss';

import { Checkbox } from 'react-materialize';

import TimelineIcon from '@mui/icons-material/Timeline';
import ChatIcon from '@mui/icons-material/Chat';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

class ReportTest extends React.Component {

  constructor() {
    super()

    this.state = {
      showNoteInput: false,
      showHistory: false,
      showDelete: false,
      // pressActive: false, // used to fire longpress
      showLimitsEditInputs: false,
      rangeIndicator: null,
      DEBUG_chekd: undefined
    }
  }

  componentDidMount() {
    let canvas = document.getElementById(`history-${this.props.test.testId}`);
    if (canvas && !canvas.classList.contains('drawn')) {
      this.drawHistoryChart(canvas);
      canvas.classList.add('drawn');
    }
    this.calcRangeIndicator();
  }

  validateResult(result) { // make sure value entered in result is a valid number
    if (typeof result === 'string') {
      return result?.replace(/[^0-9.]/g,'');
    } else if (typeof result === 'boolean') {
      return result;
    }
  }

  focusInput() {
    if (this.state.showDelete) {return false}
    document.querySelectorAll(`.result[testid="${this.props.test.id}"]`)[0].focus();
    [...document.querySelectorAll('.node-option')].forEach((o) => {o.classList.remove('shown')})

    let options = [...document.querySelectorAll(`.options[data-id="${this.props.test.id}"]`)[0].children];
    options.forEach((o) => {
      o.classList.add("shown")
    })
  }

  formatLimitsText(min, max) {
    if (min && max) {
      return <span class="limits">{` (${min} — ${max})`}</span>
    } else if (min) {
      return <span class="limits">{` (min ${min})`}</span>
    } else if (max) {
      return <span class="limits">{` (max ${max})`}</span>
    } else {
      return null
    }
  }

  calcRangeIndicator(result, min, max) {
    //calc how the range indicator appears on the test result by adjusting its color and the (height of its container)

    // sometimes we wanna supply parameters for immediate update on input change
    result = parseFloat(result === undefined ? this.props.test.result : result);
    min = parseFloat(min === undefined ? this.props.test.min : min);
    max = parseFloat(max === undefined ? this.props.test.max : max);

    // console.log('calcing', result, min, max);

    let indicatorHeight = 50; // 10 to 50 px
    let indicatorColor = 'red';

    if (max && result > max) {
      // console.log(result, min, max, 'fired 1');
      if (result > (max+max*.2)) {
        // console.log(result, min, max, 'fired 2');

        indicatorHeight = 50;
        indicatorColor = 'red';
      } else {
        // console.log(result, min, max, 'fired 3');

        indicatorHeight = 40;
        indicatorColor = 'orange';
      }
    } else if (min && result < min) {
      // console.log(result, min, max, 'fired 4');

      if (result < (min-min*.2)) {
        // console.log(result, min, max, 'fired 5');

        indicatorHeight = 10;
        indicatorColor = 'red';
      } else {
        // console.log(result, min, max, 'fired 6');

        indicatorHeight = 20;
        indicatorColor = 'orange';
      }
    } else {
      // console.log(result, min, max, 'fired 7');

      indicatorHeight = 30;
      indicatorColor = 'green';
    }

    if (!result || !(min || max)) {
      indicatorHeight = 0;
      indicatorColor = 'rgba(#000000, 0)'; // invisible
    }

    this.setState({rangeIndicator: {
      height: indicatorHeight,
      color: indicatorColor
    }});
  }

  calcResultFontSize() {
    let length = `${this.props.test?.result}`.length - 5;
    let fontSize = 24;

    if (length > 0) {
      fontSize = fontSize - (4 + length*1.4);
    }

    return fontSize
  }

  buildScaffold(patch) {
    let update = {...this.props.test, ...patch};
    let crumbs = update.breadcrumbs?.split('.');
  
    if (crumbs) { // report update sent breadcrumb trail with update obj so we need to build the report structure to merge with existing report
      let reportId = crumbs.reverse().pop();

      let reportStructure = {
        id: reportId,
        ...crumbs.reduce((val, key) => ({[key]: val}), {...update})
      };

      return reportStructure;
    }
  }

  handleHistoryToggle() {
    let toggle = !this.state.showHistory;

    this.setState({showHistory: toggle});
  }

  handlePress() {    
    this.focusInput();
  }

  handleLongPress(e) {
    console.log('_______________TRIGGERED', e.target)
    this.setState({pressActive: true});
    setTimeout(() => {
      if (this.state.pressActive) {
        this.setState({pressActive: false})
        e.preventDefault();
        this.setState({showDelete: true});
        // console.log(e);
      }
    }, 500)
  }

  render() {
    if (this.props.test.deleted) {return null};
    
    if (this.props.test.type === 'checkbox') {
      return (
        <div class="node-container" data-id={this.props.test.id} data-test={this.props.test.testId}>
          <div
            class="report-node"
            onClick={() => this.props.updateReport(
              this.buildScaffold({...this.props.test, result: !this.props.test.result}), 
              {fridge: true, freezer: this.props.authToken},
            )}
            onMouseLeave={() => this.setState({pressActive: false})}
            draggable="false"
          >
            <div class="node-test">
              <div class="test-field">
                <span class="test-name">{this.props.test.test_name}</span>
              </div>
  
              <div class="result-container">
                <input
                  id={`test-${this.props.test.id}`}
                  testid={this.props.test.id}
                  class="result-checkbox"
                  type="checkbox"
                  checked={this.props.test.result === true ? 'checked' : false}
                />
                
                <label class="checkbox-label" for={`test-${this.props.test.id}`}>
                  <div class="tick-mark"></div>
                </label>
              </div>
            </div>

            <div className={`delete-node ${this.state.showDelete ? 'shown' : ''}`}>
              <div 
                class="delete-text"
                onClick={(e) => { 
                  e.stopPropagation(); 
                  this.props.updateReport(this.buildScaffold({deleted: true}), {fridge: true, freezer: this.props.authToken}); 
                }}
              >
                Delete?
              </div>
              <DeleteIcon />
              <div class="never-mind" onClick={(e) => {e.stopPropagation(); this.setState({showDelete: false})}}>Never mind</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div class="node-container" data-id={this.props.test.id} data-test={this.props.test.testId}>
          <div class="options" data-id={this.props.test.id}>
            {this.props.test.history?.length ? (
              <button class="node-option result-history-toggle" onClick={(e) => this.handleHistoryToggle()}>
                <TimelineIcon style={{fontSize: 20}}/>
                Trend
              </button>
            ) : null}
            <button class="node-option limits-edit-toggle" onClick={() => this.setState({showLimitsEditInputs: !this.state.showLimitsEditInputs})}>
              <SettingsEthernetIcon style={{fontSize: 20}}/>
              Limits
            </button>
            <button class="node-option notes-toggle" onClick={() => this.setState({showNoteInput: true})}>
              <ChatIcon style={{fontSize: 20}}/>
              Note
            </button>
            <button class="node-option delete-trigger" onClick={() => this.setState({showDelete: true})}>
              <DeleteIcon style={{fontSize: 20}}/>
              Delete
            </button>

          </div>
          <div
            class="report-node"
            onClick={() => this.handlePress()}
            draggable="false"
          >
            <div class="node-test">
              <div class="test-field">
                <span class="test-name">{this.props.test.test_name}</span>
                {this.formatLimitsText(this.props.test.min, this.props.test.max)}
                <br />
                <span class="additional">{this.props.test.unit_of_measure}</span>
              </div>
  
              <div class="result-container">
                <div class="range-indicator-container" style={{height: this.state.rangeIndicator?.height}}>
                  <div class="range-indicator" style={{backgroundColor: this.state.rangeIndicator?.color}}/>
                </div>
                <input
                  testid={this.props.test.id}
                  class="result"
                  type="text"
                  value={this.props.test.result}
                  style={{fontSize: this.calcResultFontSize()}}
                  onChange={(e) => {
                    this.props.updateReport(
                      this.buildScaffold({...this.props.test, result: e.target.value}), 
                      {fridge: true},
                      () => this.calcRangeIndicator(e.target.value)
                    )
                  }}
                  onBlur={(e) => this.props.updateReport(this.buildScaffold({result: e.target.value}), {freezer: this.props.authToken})}
                  tabindex={this.props.tabIndex}
                />
                <span class="width-calc"></span>
              </div>
            </div>
  
            {this.state.showDelete ? (
              <div className={`delete-node shown`}>
                <div 
                  class="delete-text"
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    this.props.updateReport(this.buildScaffold({deleted: true}), {fridge: true, freezer: this.props.authToken}); 
                  }}
                >
                  Delete?
                </div>
                <DeleteIcon />
                <div class="never-mind" onClick={(e) => {e.stopPropagation(); this.setState({showDelete: false})}}>Never mind</div>
              </div>
            ) : null}
  
            <i className={`test-note ${this.state.showNoteInput ? '' : 'shown'}`}>
              {this.props.test.note}
            </i>
  
            <div className={`test-note-container ${this.state.showNoteInput ? 'shown' : ''}`}>
              <TextareaAutosize
                className="test-note-input"
                minRows={2}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();}}
                placeholder="Enter a note regarding this test result"
                value={this.props.test.note}
                onChange={(t) => this.props.updateReport(this.buildScaffold({note: t.target.value}), {fridge: true})}
              />
              <button
                class="submit-note"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.updateReport(
                    this.buildScaffold({note: this.props.test.note}), 
                    {freezer: this.props.authToken},
                    this.setState({showNoteInput: false})
                  );
                }}
              >
                <CheckIcon style={{fontSize: 36}} />
              </button>
            </div>
  
            {this.state.showLimitsEditInputs ? (
              <div class="limits-input-container">
                Min: <input 
                  onClick={(e) => {e.stopPropagation(); e.preventDefault();}}
                  value={this.props.test.min}
                  onChange={(e) => this.props.updateReport(
                    this.buildScaffold({min: this.validateResult(e.target.value)}), 
                    {fridge: true},
                    () => this.calcRangeIndicator(undefined, e.target.value, undefined)
                  )}
                  onBlur={(e) => this.props.updateReport(
                    this.buildScaffold({min: e.target.value}), 
                    {freezer: this.props.authToken},
                    null
                  )}
                /> 
                
                Max: <input 
                  onClick={(e) => {e.stopPropagation(); e.preventDefault();}}
                  value={this.props.test.max}
                  onChange={(e) => this.props.updateReport(
                    this.buildScaffold({max: this.validateResult(e.target.value)}), 
                    {fridge: true},
                    () => this.calcRangeIndicator(undefined, undefined, e.target.value)
                  )}
                  onBlur={(e) => this.props.updateReport(
                    this.buildScaffold({max: e.target.value}), 
                    {freezer: this.props.authToken},
                    null
                  )}
                /> 
              </div>
            ) : null}
  
            {this.state.showHistory ? (
              <ReportTestHistory test={this.props.test}/>
            ) : null}
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTest);
