import React from "react";

class ReportNote extends React.Component {
  render() {
    if (this.props.node.deleted) {return null}
    
    if (this.props.node.iAm === 'note') {
      return (
        <div class="node-container" onClick={() => this.props.handleClick()}>
          <div class="note-container">
            <div class="report-note">
              {this.props.node.content?.length > 0 ? this.props.node.content : 'Empty note, click to edit'}
            </div>
          </div>
        </div>
      )
    } else if (this.props.node.iAm ==='image') {
      return (
        <div class="node-container" onClick={() => this.props.handleClick()}>
          <div class="report-node image">
            <img class="note-image" src={this.props.node.image} alt={this.props.node.content} />
            <div class="note-content">{this.props.node.content}</div>
          </div>
        </div>
      )
    } else {return null}
  }
}

export default ReportNote