import React from 'react';
import './styles/dash.scss';
import { Button } from 'react-materialize';

import DescriptionIcon from '@mui/icons-material/Description';


class Dashboard extends React.Component {

  nav(path) {
    window.location = "/"+path;
  }

  render() {
    return (
      <div class="dash">
        <div class="row">
          <Button className="option report" onClick={() => this.nav('reports')}>
            <div class="icon-container">
              <img class="icon" src="./beaker.png" alt="reports icon"/>
            </div>

            <span class="title">
              Reports
            </span>
          </Button>

          <a  href="https://www.proasysinc.com/orders" className="option order">
            <div class="icon-container">
              <img class="icon" src="./cart.png" alt="orders icon"/>
            </div>

            <span class="title">
              ORDERS
            </span>
          </a>
        </div>

        <div class="row">

        <Button className="option lookup" onClick={() => this.nav('lookup')}>
          <div class="icon-container">
            <img class="icon" src="./lookup.png" alt="lookup iconZ"/>
          </div>

          <span class="title">
            Lookup
          </span>
        </Button>

        <Button className="option schedule" disabled onClick={() => this.nav('schedule')}>
          <div class="icon-container">
          </div>

          <span class="title">
            Under Construction
          </span>
        </Button>
        </div>
      </div>
    )
  }
}

export default Dashboard;
