import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import '../styles/lookup.scss';
import SearchIcon from '@mui/icons-material/Search';

// import { Autocomplete } from 'react-materialize';
import Autocomplete from '../components/Autocomplete';
import 'materialize-css';

class Search extends React.Component {

  constructor() {
    super();

    this.state = {
      searchList: null,

    };
  }

  componentDidMount() {
    this.props.fetchAccounts(this.props.authToken, () => {
      this.props.fetchProducts(this.props.authToken, 'all', () => {
        this.setState({searchList: this.formatSearchOptions()});
      });
    });

    document.getElementById("account-select").focus()
  }

  formatSearchOptions() {    
    let searchList = {};

    this.props.products?.map(p => (searchList[p.model] = `${p.id}`));
    this.props.accounts?.map(a => (searchList[a.name] = `${a.id}`));
    
    // console.log('formatsearchoptions', newObj);

    return searchList;
  }

  newReport() {  // why do we have this here
    this.props.createReport(
      // customer, reportType, eventId, token, onSuccess
      this.state.accountSelected,
      this.state.reportType,
      null,
      this.props.authToken,
      (newReportUrl) => {window.location.href = `/reports/edit?${newReportUrl}`}
    )
  }

  handleOptionSelect(option) {

  }

  render() {
    return (
      <div className="search-container">
        <SearchIcon className='search-icon'/>
        {/* <Autocomplete
          id="account-select"
          className="lookup-search-account-input"
          options={{
            data: this.state.searchList,
            onAutocomplete: (searchName) => {
              console.log('SEARCHED', searchName)
              // console.log(this.state.searchList[searchName]);
              let obj = this.findObjByName(searchName);

              this.props.fetchInfo(this.props.authToken, obj.id, obj.type, null);
            }
          }}
          placeholder="Enter an account or product"
          onChange={() => this.setState({accountSelected: null})}
        /> */}

        <Autocomplete 
          list={this.formatSearchOptions()} 
          onSelect={(option) => this.handleOptionSelect(option)}
          placeholder="Enter an account or product"
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    accounts: state.accounts,
    products: state.products
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
